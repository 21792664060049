import {api} from "@store/api/api";
import {PaginationOptions} from "@app-types/PaginationOptions";

export const items = api.injectEndpoints({
    endpoints: (build) => ({
        /**
         * Get item endpoint.
         */
        getItem: build.query({
            query: (id) => ({
                url: `/api/items/${id}`,
                method: "GET"
            }),
            providesTags: ["Item"]
        }),

        /**
         * Add item endpoint.
         */
        addItem: build.mutation({
            query: (body) => ({
                url: "/api/items",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["Item"]
        }),

        /**
         * Get items collection endpoint.
         */
        getAllItems: build.query<any, PaginationOptions>({
            query: (paginationOptions) => ({
                url: "/api/items",
                method: "GET",
                params: {
                    pagination: paginationOptions.pagination === false ? false : true,
                    itemsPerPage: paginationOptions.itemsPerPage,
                    page: paginationOptions.page,
                    ...(paginationOptions.search && { search: paginationOptions.search })
                },
                refetchOnMountOrArgChange: true
            }),
            providesTags: ["Item"]
        }),

        /**
         * Delete item endpoint.
         */
        deleteItem: build.mutation({
            query: (id) => ({
                url: `/api/items/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Item", "Checklist", "ChecklistCategory", "Check"]
        }),

        /**
         * Update item endpoint.
         */
        updateItem: build.mutation({
            query: ({id, body}) => ({
                url: `/api/items/${id}`,
                method: "PUT",
                body: body
            }),
            invalidatesTags: ["Item"]
        }),
    })
});

export const { useAddItemMutation, useGetAllItemsQuery, useDeleteItemMutation, useUpdateItemMutation, useGetItemQuery } = items;
