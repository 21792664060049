import banner from '@assets/images/checkr/checkr.png';
import React from 'react';
import { Grid, styled } from '@mui/material';
import './HomeBanner.styles.scss';

const BannerQuoteSpan = styled("span")(({ theme }) => ({
    fontWeight: theme.fontWeights.semiBold,
}));

/**
 * Banner component that displays on the right half of the home layout.
 * @constructor
 */
export default function HomeBanner() {
    return (
        <Grid container item id={"home-banner"} xs={12} lg={6}>
            <img
                data-testid={"home-banner-img"}
                id={"home-banner-img"}
                src={banner}
                alt={"Laptop that's using the Check'r application"}
            />
            <Grid container item id={"home-banner-quote-container"} spacing={1} xs={12} p={2}>
                <Grid item id={"home-banner-quote"} xs={12}>
                    <BannerQuoteSpan>"Vertrouwen is goed, controle is beter."</BannerQuoteSpan>
                </Grid>
                <Grid item id={"home-banner-quote-author"} xs={12}>
                    <BannerQuoteSpan>Vladimir Lenin</BannerQuoteSpan>
                </Grid>
            </Grid>
        </Grid>
    );
}
