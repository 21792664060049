import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {checkrTheme} from "theme";
import SecureElement from "@components/routing/SecureElement";
import LoginPage from "@views/LoginPage/LoginPage";
import "./App.styles.scss";
import UsersPage from "@views/UsersPage/UsersPage";
import ProjectsPage from "@views/ProjectsPage/ProjectsPage";
import ChecksPage from "@views/ChecksPage/ChecksPage";
import TemplatesPage from "@views/TemplatesPage/TemplatesPage";
import ItemsPage from "@views/ItemsPage/ItemsPage";
import ProjectTypesPage from "@views/ProjectTypesPage/ProjectTypesPage";
import {Roles} from "@config/constants/security";
import TemplateItemsPage from "@views/TemplatesPage/TemplateItemsPage/TemplateItemsPage";
import {DndProvider} from "react-dnd-multi-backend";
import {HTML5toTouch} from "rdndmb-html5-to-touch";
import NewCheckPage from "@views/ChecksPage/NewCheckPage/NewCheckPage";
import ViewCheckPage from "@views/ChecksPage/ViewCheckPage/ViewCheckPage";

export default function App() {
    return (
        <ThemeProvider theme={checkrTheme}>
            <DndProvider options={HTML5toTouch}>
                <Router>
                    <Routes>
                        <Route path={"/login"} element={
                            <LoginPage/>
                        }/>
                        <Route path={"/"} element={
                            <SecureElement>
                                <ProjectsPage/>
                            </SecureElement>
                        }/>
                        <Route path={"/projects"} element={
                            <SecureElement>
                                <ProjectsPage/>
                            </SecureElement>
                        }/>
                        <Route path={"/checks/:id/view"} element={
                            <SecureElement>
                                <ViewCheckPage/>
                            </SecureElement>
                        }/>
                        <Route path={"/checks/new"} element={
                            <SecureElement>
                                <NewCheckPage/>
                            </SecureElement>
                        }/>
                        <Route path={"/checks"} element={
                            <SecureElement>
                                <ChecksPage/>
                            </SecureElement>
                        }/>
                        <Route path={"/users"} element={
                            <SecureElement role={Roles.SuperAdmin.toString()}>
                                <UsersPage/>
                            </SecureElement>
                        }/>
                        <Route path={"/templates/:id"} element={
                            <SecureElement role={Roles.SuperAdmin.toString()}>
                                <TemplateItemsPage/>
                            </SecureElement>
                        }/>
                        <Route path={"/templates"} element={
                            <SecureElement role={Roles.SuperAdmin.toString()}>
                                <TemplatesPage/>
                            </SecureElement>
                        }/>
                        <Route path={"/items"} element={
                            <SecureElement role={Roles.SuperAdmin.toString()}>
                                <ItemsPage/>
                            </SecureElement>
                        }/>
                        <Route path={"/project-types"} element={
                            <SecureElement  role={Roles.SuperAdmin.toString()}>
                                <ProjectTypesPage/>
                            </SecureElement>
                        }/>
                    </Routes>
                </Router>
            </DndProvider>
        </ThemeProvider>
    )
}
