import React, { ChangeEvent, ReactNode } from 'react';
import { getItemAriaLabel, getLabelDisplayedRows } from '@utils/helpers/pagination.helpers';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';

interface Props {
    header: ReactNode,
    body: ReactNode,
    count: number,
    page: number,
    setPage: Function,
    rowsPerPage: number,
    setRowsPerPage: Function,
    tableClasses?: string,
    small?: boolean
}
export default function PaginationTable(props: Props) {

    /**
     * Changes the page when the previous or next button is pressed.
     * @param event The event that triggered the function.
     * @param newPage The page that should be displayed in the table.
     */
    function handlePageChange(event: unknown, newPage: number) {
        props.setPage(newPage + 1);
    }

    /**
     * Changes the amount of entities that should be displayed on a page when a different value is selected in the dropdown.
     * @param event The event that triggered the function.
     */
    function handleRowsPerPageChange(event: ChangeEvent<HTMLInputElement>) {
        props.setRowsPerPage(+event.target.value);
        props.setPage(1);
    }

    return (
        <TableContainer>
            <Table className={props.tableClasses}>
                <TableHead>
                    {props.header}
                </TableHead>
                <TableBody>
                    {props.body}
                    <TableRow>
                        <TablePagination
                            count={props.count}
                            page={props.page - 1}
                            rowsPerPage={props.rowsPerPage}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            labelRowsPerPage={"Rijen per pagina:"}
                            labelDisplayedRows={getLabelDisplayedRows}
                            getItemAriaLabel={getItemAriaLabel}
                            showFirstButton
                            showLastButton
                        />
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
