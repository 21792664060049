import { styled, Grid } from "@mui/material";

const DialogContentGridContainer = styled(Grid)(({ theme }) => ({
    position: "relative",
    padding: "52px 67px 52px 67px",

    [theme.breakpoints.down("lg")]: {
        padding: "26px 33px 26px 33px",
    },
}));

DialogContentGridContainer.defaultProps = { 
    container: true 
};

export default DialogContentGridContainer;