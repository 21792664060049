import {Grid, styled} from "@mui/material";
import React, {ReactNode} from "react";
import "./TopBar.styles.scss";

const TopBarGrid = styled(Grid)(({theme}) => ({
    padding: "0 40px",

    [theme.breakpoints.down("lg")]: {
        padding: "0 16px",
    },
}));

interface Props {
    children?: ReactNode
}

/**
 * Top bar component that displays on the top of the dashboard layout.
 * @param children The components that should be displayed inside the top bar.
 * @constructor
 */
export default function TopBar({children}: Props) {
    return (
        <TopBarGrid container item data-testid={"topbar"} id={"topbar"}>
            {children}
        </TopBarGrid>
    );
}
