import React, {ReactNode} from "react";
import logo from "@assets/images/logo/Group 8174.svg";
import "./HomeLayout.styles.scss";
import HomeBanner from "@components/layouts/HomeLayout/HomeBanner/HomeBanner";
const {Grid} = require("@mui/material");

interface Props {
    children: ReactNode
}

/**
 * Component that defines the base layout of public pages outside of the dashboard.
 * @param children The components that should be displayed inside the layout.
 * @constructor
 */
export default function HomeLayout({children}: Props) {
    return (
        <Grid container id={"home-layout"}>
            <Grid container item xs={12} lg={6}>
                <Grid container item id={"home-logo"} xs={12}>
                    <img data-testid={"logo"} src={logo} alt={"Check'r logo"}/>
                </Grid>
                <Grid container item id={"home-children-container"} xs={12}>
                    {children}
                </Grid>
            </Grid>
            <HomeBanner/>
        </Grid>
    )
}
