import CrudPageLayout from '@components/layouts/CrudPageLayout/CrudPageLayout';
import React, { useState } from 'react';
import { addUserValidationSchema } from '@config/constants/validation-schemas/user/addUserValidationSchema';
import { CrudTable } from '@components/tables';
import { getUserErrorMessage } from '@utils/helpers/errorMessage.helpers';
import { Roles } from '@config/constants/security';
import { updateUserValidationSchema } from '@config/constants/validation-schemas/user/updateUserValidationSchema';
import {
    useAddUserMutation,
    useDeleteUserMutation,
    useGetAllUsersQuery,
    useUpdateUserMutation
} from '@store/api/users';
import { UserForm } from '@components/forms';
import { UserRequestBody } from '@app-types/request-bodies/UserRequestBody';

/**
 * Users page component using the dashboard layout.
 * @constructor
 */
export default function UsersPage() {
    const [tablePage, setTablePage] = useState(1);
    const [rowsPerTablePage, setRowsPerTablePage] = useState(10);
    const [searchValue, setSearchValue] = useState("");

    const [addUser] = useAddUserMutation();
    const { data, isSuccess, isFetching, isError, refetch } = useGetAllUsersQuery({
        page: tablePage,
        itemsPerPage: rowsPerTablePage,
        ...(searchValue !== "" && { search: searchValue })
    });

    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);

    const [validationMessage, setValidationMessage] = useState("");
    const [openValidationSnackbar, setOpenValidationSnackbar] = useState(false);

    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [updateDialog, setUpdateDialog] = useState<{ open: boolean, entity: UserRequestBody | undefined }>({
        open: false,
        entity: undefined
    });

    const [deleteUser] = useDeleteUserMutation();
    const [updateUser] = useUpdateUserMutation();

    /**
     * Shows the confirmation snackbar.
     * @param message The message the confirmation snackbar should display.
     */
    function showConfirmationSnackbar(message: string) {
        setConfirmationMessage(message);
        setOpenConfirmationSnackbar(true);
    }

    React.useEffect(() => setTablePage(1), [searchValue]);

    return (
        <CrudPageLayout
            addDialogTitle={"Gebruiker toevoegen"}
            addDialogForm={
                <UserForm
                    closeSelf={() => {
                        if (openAddUserDialog) refetch();
                        setOpenAddUserDialog(!openAddUserDialog);
                    }}
                    action={addUser}
                    validationSchema={addUserValidationSchema}
                    buttonText={"Toevoegen"}
                    showConfirmation={() => {
                        setConfirmationMessage("De gebruiker is succesvol toegevoegd.");
                        setOpenConfirmationSnackbar(true);
                    }}
                    setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                />
            }
            openAddDialog={openAddUserDialog}
            setOpenAddDialog={setOpenAddUserDialog}
            openConfirmationSnackbar={openConfirmationSnackbar}
            setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
            openValidationSnackbar={openValidationSnackbar}
            setOpenValidationSnackbar={setOpenValidationSnackbar}
            validationMessage={validationMessage}
            confirmationMessage={confirmationMessage}
            getAllIsError={isError}
            getAllRefetch={refetch}
            pageHeading={"Gebruikers"}
            addRole={Roles.SuperAdmin}
            setSearchValue={setSearchValue}
        >
            <CrudTable
                data={data}
                isSuccess={isSuccess}
                isFetching={isFetching}
                refetch={refetch}
                page={tablePage}
                rowsPerPage={rowsPerTablePage}
                setPage={setTablePage}
                setRowsPerPage={setRowsPerTablePage}
                setConfirmationMessage={setConfirmationMessage}
                setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                setValidationMessage={setValidationMessage}
                setOpenValidationSnackbar={setOpenValidationSnackbar}
                getErrorMessage={getUserErrorMessage}
                deleteAction={deleteUser}
                columns={[
                    { id: "name", label: "Gebruiker" },
                    { id: "role", label: "Rol" },
                    { id: "email", label: "E-mailadres" },
                    { id: "options", label: "Opties", classes: "options-cell" }
                ]}
                deleteConfirmationMessage={"De gebruiker is succesvol verwijderd."}
                updateDialogTitle={"Gebruiker wijzigen"}
                deleteDialogText={"Weet je zeker dat je deze gebruiker wilt verwijderen?"}
                updateForm={
                    <UserForm
                        closeSelf={() => setUpdateDialog({ open: !updateDialog.open, entity: updateDialog.entity })}
                        action={updateUser}
                        validationSchema={updateUserValidationSchema}
                        buttonText={"Wijzigen"}
                        userId={updateDialog.entity ? updateDialog.entity["id"] : undefined}
                        firstName={updateDialog.entity ? updateDialog.entity["firstName"] : undefined}
                        lastName={updateDialog.entity ? updateDialog.entity["lastName"] : undefined}
                        email={updateDialog.entity ? updateDialog.entity["email"] : undefined}
                        role={updateDialog.entity ? updateDialog.entity["role"] : undefined}
                        showConfirmation={() => showConfirmationSnackbar("De gebruiker is succesvol gewijzigd.")}
                        setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                    />
                }
                updateDialog={updateDialog}
                setUpdateDialog={setUpdateDialog}
            />
        </CrudPageLayout>
    );
}
