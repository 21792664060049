import {roleHierarchy} from "@config/constants/security";
import {NavigateFunction} from "react-router-dom";
import {Dispatch} from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import {JwtPayload} from "@app-types/JwtPayload";
import {logout} from "@store/slices/auth";

/**
 * Determines if a user has access to a secure page based on the role hierarchy.
 * @param userRoles The roles the user has.
 * @param requiredRole The highest role that is required.
 */
export default function userHasAccess(userRoles: string[], requiredRole: string) {
    let hasAccess = false;

    for(let i = userRoles.length-1; i >= 0; i--) {
        let accessRoles = roleHierarchy[userRoles[i]];
        if (!hasAccess && accessRoles.includes(requiredRole)) hasAccess = true;
    }

    return hasAccess;
}

/**
 * Logs out if a token is invalid.
 * @param token The token that should be checked.
 * @param dispatch The dispatch function.
 * @param navigate The navigate function.
 */
export function logoutIfInvalidToken(token: string | null, dispatch: Dispatch, navigate: NavigateFunction) {
    let redirect = true;

    if(token !== null) {
        const user = jwtDecode<JwtPayload>(token);

        if (user.exp && Date.now() < user.exp * 1000) redirect = false;
    }

    if (redirect) {
        dispatch(logout());
        navigate("/login", {replace: true});
    }
}
