import { Button, styled } from '@mui/material';

const TopBarButton = styled(Button)(({ theme }) => ({
    height: "44px",
    margin: "13px 0",
    fontSize: "13px",

    [theme.breakpoints.down("lg")]: {
        margin: "8px 0",
    },
}));

TopBarButton.defaultProps = {
    fullWidth: false,
};

export default TopBarButton;
