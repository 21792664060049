import { Grid, styled } from '@mui/material';

const PageContentGridItem = styled(Grid)({
    minHeight: "calc(100% - 64px)",
    width: "100%",
});

PageContentGridItem.defaultProps = {
    item: true,
};

export default PageContentGridItem;