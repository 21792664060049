import React from 'react';
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import '@views/App/App.styles.scss';
import {PersistGate} from "redux-persist/integration/react";
import App from "@views/App/App";
import {index, persistor} from "@store/index";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={index}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
