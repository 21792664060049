import {Grid, TableCell, TableRow} from "@mui/material";
import React from "react";
import {ReactComponent as DragIcon} from "@assets/images/icons/arrows-up-down-left-right-light.svg";
import {useDrag} from "react-dnd";

interface Props {
    item: any;
    type: string;
    category?: any;
}

/**
 * Draggable row component for the ItemsTable component.
 * @param item The item to be displayed in the row.
 * @param type The type of the item.
 * @param category The category the item belongs to. (Optional)
 * @constructor
 */
export default function DraggableTableRow({item, type}: Props) {
    const [{ isDragging }, drag] = useDrag({
        type: type,
        item: {
            id: item.id,
            categoryId: item.category?.id,
            itemId: item.item?.id
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    return (
        <TableRow key={item.id} className={"draggable-row"} sx={{ opacity: isDragging ? 0.5 : 1 }} ref={drag} draggable>
            <TableCell>
                <Grid container className={"icon-container"}>
                    <DragIcon/>
                </Grid>
            </TableCell>
            <TableCell>{item.item?.value ?? item.value}</TableCell>
        </TableRow>
    );
}
