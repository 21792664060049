import {createSlice} from "@reduxjs/toolkit";
import {UserResponseBody} from "@app-types/response-bodies/UserResponseBody";

interface AuthState {
    token: string|null,
    me: UserResponseBody|null
}

const initialState: AuthState = {
    token: null,
    me: null
}

/**
 * Authentication actions.
 */
export const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.token = action.payload;
        },
        setMe: (state, action) => {
            state.me = action.payload
        },
        logout: (state) => {
            state.token = initialState.token;
            state.me = initialState.me;
        },
    }
});

export const {login, setMe, logout} = auth.actions;
