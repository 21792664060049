import {logout} from "@store/slices/auth";
import {getChecklistCategoryErrorMessage} from "@utils/helpers/errorMessage.helpers";
import {useFormik} from "formik";
import {AnyObjectSchema} from "yup";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Button, TextField} from "@mui/material";
import {FormSnackbar} from "@components/snackbars";
import {ChecklistCategoryRequestBody} from "@app-types/request-bodies/ChecklistCategoryRequestBody";

/**
 * @param closeSelf The function that closes the dialog.
 * @param projectName Default value for the name input field. (optional)
 * @param action The mutation action that should be executed on submit.
 * @param validationSchema The yup form validation schema that the form should use.
 * @param buttonText The text that the submit button should have.
 * @param projectTypeId The id of the project type that needs to be updated (optional)
 * @param showConfirmation Function that shows the confirmation snackbar with the correct message.
 */
interface Props {
    closeDialog: Function,
    category?: any,
    action: Function,
    validationSchema: AnyObjectSchema,
    buttonText: string,
    checklistId?: any,
    showConfirmation: Function,
    setOpenConfirmationSnackbar: Function
}

/**
 * Checklist category form component that is used for adding and updating checklist categories.
 * @constructor
 */
export default function ChecklistCategoryForm(props: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    /**
     * Initialization of the formik form.
     */
    const formik = useFormik({
        initialValues: {
            name: props.category?.name ?? "",
        },
        validationSchema: props.validationSchema,
        onSubmit: async (values: ChecklistCategoryRequestBody) => {
            try {
                props.setOpenConfirmationSnackbar(false);

                let body: ChecklistCategoryRequestBody = {
                    name: values.name,
                };
                if (props.checklistId) body.checklistId = +props.checklistId;

                if(props.category?.id !== undefined) {
                    await props.action({id: props.category.id, body: body}).unwrap();
                } else {
                    await props.action(body).unwrap();
                }

                props.showConfirmation();
                props.closeDialog();
            } catch (error: any) {
                if (error.data.message === "Expired JWT Token") {
                    dispatch(logout());
                    navigate("/login", {replace: true});
                }

                setErrorMessage(getChecklistCategoryErrorMessage(error));
                setOpenSnackbar(true);
            }
        }
    });

    return (
        <form data-testid={"form"} onSubmit={formik.handleSubmit} noValidate>
            <TextField
                id={"categoryName"}
                name={"name"}
                label={"Naam"}
                type={"text"}
                margin={"dense"}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{
                    "data-testid": "categoryName"
                }}
            />
            <Button data-testid={"submit"} type={"submit"}>{props.buttonText}</Button>
            <FormSnackbar
                open={openSnackbar}
                setOpen={setOpenSnackbar}
                message={errorMessage}
                severity={"error"}
            />
        </form>
    );
}
