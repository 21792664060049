export enum Roles {
    User = "ROLE_USER",
    Admin = "ROLE_ADMIN",
    SuperAdmin = "ROLE_SUPER_ADMIN"
}

export const roleHierarchy: {[key: string]: string[]} = {
    "ROLE_USER": [Roles.User],
    "ROLE_ADMIN": [Roles.Admin, Roles.User],
    "ROLE_SUPER_ADMIN": [Roles.SuperAdmin, Roles.Admin, Roles.User]
}
