import { api } from "@store/api/api";
import { PaginationOptions } from "@app-types/PaginationOptions";

export const checks = api.injectEndpoints({
    endpoints: (build) => ({
        /**
         * Get check endpoint.
         */
        getCheck: build.query({
            query: (id) => ({
                url: `/api/checks/${id}`,
                method: "GET",
            }),
            providesTags: ["Check"]
        }),

        /**
         * Add check endpoint.
         */
        addCheck: build.mutation({
            query: (body) => ({
                url: "/api/checks",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["Check", "Project"]
        }),

        /**
         * Get checks collection endpoint.
         */
        getAllChecks: build.query<any, PaginationOptions>({
            query: (paginationOptions) => ({
                url: "/api/checks",
                method: "GET",
                params: {
                    itemsPerPage: paginationOptions.itemsPerPage,
                    page: paginationOptions.page,
                    ...(paginationOptions.search && { search: paginationOptions.search })
                },
                refetchOnMountOrArgChange: true
            }),
            providesTags: ["Check"]
        }),

        /**
         * Get all results of a check.
         */
        getCheckResults: build.query({
            query: (id) => ({
                url: `/api/checks/${id}/results`,
                method: "GET",
            }),
            providesTags: ["Check"]
        }),
    })
});

export const { useAddCheckMutation, useGetAllChecksQuery, useGetCheckQuery, useGetCheckResultsQuery } = checks;
