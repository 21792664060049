import "./FormSnackbar.styles.scss";
import {Alert, AlertColor, Slide, SlideProps, Snackbar} from "@mui/material";
import React, {SyntheticEvent} from "react";

interface Props {
    open: boolean,
    setOpen:  Function,
    message: string,
    severity: AlertColor,
    autoHideDuration?: number
}

/**
 * The transition of the snackbar.
 * @param props The properties the slide transition should have.
 * @constructor
 */
function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

/**
 * Form snackbar component that displays server feedback to the user.
 * @param open State that shows whether the snackbar is open.
 * @param setOpen Function that sets the open state.
 * @param message State that shows the message that should be displayed.
 * @param severity The alert color that the snackbar should have.
 * @param autoHideDuration The duration the snackbar should be displayed before automatically closing. (optional)
 * @constructor
 */
export default function FormSnackbar({open, setOpen, message, severity, autoHideDuration}: Props) {

    /**
     * Closes the snackbar.
     * @param event The event that is triggered.
     * @param reason The reason the snackbar should close.
     */
    function handleClose(event: Event|SyntheticEvent<any, Event>, reason: string) {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }
    return (
        <Snackbar
            id={"form-snackbar"}
            data-testid={"snackbar"}
            autoHideDuration={autoHideDuration}
            open={open}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            onClick={() => setOpen(false)}
        >
            <Alert id={"form-snackbar-alert"} severity={severity}>{message}</Alert>
        </Snackbar>
    )
}
