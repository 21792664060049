import React, { useEffect } from 'react';
import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { useGetChecklistCategoryItemsQuery } from '@store/api/checklistCategory';

interface Props {
    category: any,
    formik: any
}

export default function ChecklistCategoryItemsForm({ category, formik }: Props) {
    const { data, isSuccess } = useGetChecklistCategoryItemsQuery(category.id);
    const columns = [
        { id: "item", label: "Vraag" },
        { id: "expectedResult", label: "Verwachtte resultaat" },
        { id: "actualResult", label: "Resultaat" },
        { id: "note", label: "Resultaat niet behaald? Omschrijf wat je ziet" }
    ]
    const [ready, setReady] = React.useState(false);

    useEffect(() => {
        if (isSuccess) {
            data["hydra:member"].forEach((categoryItem: any) => {
                if (!formik.values.results["categoryItem" + categoryItem.id]) {
                    formik.values.results.push({
                        id: categoryItem.id,
                        answer: "n/a",
                        note: ""
                    });
                }
            });

            setReady(true);
        } else {
            setReady(false);
        }
    }, [isSuccess, data]);

    function getFormikResultArrayIndex(categoryItemId: number) {
        return formik.values.results.findIndex((result: any) => result.id === categoryItemId);
    }

    return (
        <>
            <Grid item pt={"35px"}>
                <Typography variant={"h2"}>{category.name}</Typography>
            </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column: any) => (
                                    <TableCell key={column.id}>{column.label}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !ready &&
                            Array.from({ length: 10 }, (v, i) =>
                                <TableRow key={i}>
                                    {
                                        columns.map((column) => (
                                            <TableCell key={column.id}>
                                                <Skeleton variant={"text"} />
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            )
                        }
                        {
                            ready &&
                            data["hydra:member"].map((categoryItem: any) => (
                                <TableRow key={categoryItem.id}>
                                    <TableCell key={"item"} className={"valign-top"}>{categoryItem.item.value}</TableCell>
                                    <TableCell key={"expectedResult"} className={"valign-top"}>{categoryItem.item.expectedResult}</TableCell>
                                    <TableCell key={"actualResult"} className={"valign-top"} sx={{ width: "201px" }}>
                                        <FormControl className={"radio"} fullWidth>
                                            <RadioGroup
                                                row
                                                name={"results." + getFormikResultArrayIndex(categoryItem.id) + ".answer"}
                                                value={formik.values.results[getFormikResultArrayIndex(categoryItem.id)].answer}
                                                onChange={formik.handleChange}
                                            >
                                                <FormControlLabel
                                                    className={"radio-label"}
                                                    value="yes"
                                                    control={<Radio color={"secondary"} size={"small"} />}
                                                    label="Ja"
                                                />
                                                <FormControlLabel
                                                    className={"radio-label"}
                                                    value="no"
                                                    control={<Radio color={"secondary"} size={"small"} />}
                                                    label="Nee"
                                                />
                                                <FormControlLabel
                                                    className={"radio-label"}
                                                    value="n/a"
                                                    control={<Radio color={"secondary"} size={"small"} />}
                                                    label="N.v.t."
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell key={"note"} className={"valign-top"} width={"30%"}>
                                        <TextField
                                            multiline
                                            className={"multiline-textfield"}
                                            name={"results." + getFormikResultArrayIndex(categoryItem.id) + ".note"}
                                            type={"text"}
                                            margin={"none"}
                                            value={formik.values.results[getFormikResultArrayIndex(categoryItem.id)].note}
                                            onChange={formik.handleChange}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
