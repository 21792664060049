import DashboardLayout from '@components/layouts/DashboardLayout/DashboardLayout';
import React from 'react';
import { convertPhpDateTimeToLocalDateString } from '@utils/helpers/dateTime.helpers';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { PageContentGridItem, PageHeadingGridItem } from '@components/grids';
import { ResultTableRow } from '@components/tables/rows';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetChecklistQuery } from '@store/api/checklists';
import { useGetCheckQuery, useGetCheckResultsQuery } from '@store/api/checks';
import { useGetProjectQuery } from '@store/api/projects';

export default function ViewCheckPage() {
    const splitPath = window.location.pathname.split("/");
    const checkId = splitPath[splitPath.length - 2];
    const { data: check, isSuccess: checkIsSuccess } = useGetCheckQuery(checkId ?? skipToken);
    const { data: project, isSuccess: projectIsSuccess } = useGetProjectQuery(check ? check.project.split("/").pop() : skipToken);
    const { data: checklist, isSuccess: checklistIsSuccess } = useGetChecklistQuery(check ? check.checklist.split("/").pop() : skipToken);
    const { data: results, isSuccess: resultsIsSuccess } = useGetCheckResultsQuery(checkId ?? skipToken);
    const columns = [
        { id: "category", label: "Categorie" },
        { id: "item", label: "Vraag" },
        { id: "expectedResult", label: "Verwachtte resultaat" },
        { id: "actualResult", label: "Resultaat behaald?" },
        { id: "note", label: "Resultaat niet behaald? Omschrijf wat je ziet" }
    ];

    return (
        <DashboardLayout>
            <Grid container item height={"100vh !important"} id={"content"}>
                <PageHeadingGridItem>
                    <Typography variant={"h1"}> {"Check"}</Typography>
                </PageHeadingGridItem>
                <PageContentGridItem fontSize={"13px"}>
                    {
                        projectIsSuccess &&
                        <Grid item>
                            <b>Project</b><br />
                            {project.name}
                        </Grid>
                    }
                    {
                        checklistIsSuccess &&
                        <Grid item mt={"15px"}>
                            <b>Template</b><br />
                            {checklist.name}
                        </Grid>
                    }
                    {
                        checkIsSuccess &&
                        <Grid item mt={"15px"}>
                            <b>Datum</b><br />
                            {convertPhpDateTimeToLocalDateString(check.date)}
                        </Grid>
                    }
                    <Grid container item pt={"30px"} xs={12}>
                        <TableContainer>
                            <Table className={"read-only"}>
                                <TableHead>
                                    <TableRow>
                                        {
                                            columns.map((column: any) => (
                                                <TableCell key={column.id}>{column.label}</TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        resultsIsSuccess &&
                                        results["hydra:member"].map((result: any) => (
                                            <ResultTableRow key={result.id} result={result} />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </PageContentGridItem>
            </Grid>
        </DashboardLayout>
    );
}
