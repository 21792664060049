/**
 * Converts a PHP date time string to a local date string.
 * @param dateString The PHP date time string.
 */
export function convertPhpDateTimeToLocalDateString(dateString: string) {
    const date = new Date(dateString);
    return date.toLocaleDateString("nl-NL", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    });
}
