import {LabelDisplayedRowsArgs} from "@mui/material";

/**
 * Translates the pagination information to Dutch.
 * @param from The number of the first item that is displayed on the page.
 * @param to The number of the last item that is displayed on the page.
 * @param count The total amount of items in the collection.
 */
export function getLabelDisplayedRows({from, to, count}: LabelDisplayedRowsArgs) {
    return `${from}–${to} van ${count !== -1 ? count : `meer dan ${to}`}`;
}

/**
 * Translates the tooltips of the pagination buttons to Dutch.
 * @param type The button type (previous/next).
 */
export function getItemAriaLabel(type: string) {
    switch (type) {
        case "next":
            type = "volgende";
            break;
        case "previous":
            type = "vorige";
            break;
    }

    return `Ga naar de ${type} pagina`;
}
