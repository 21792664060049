import CrudPageLayout from '@components/layouts/CrudPageLayout/CrudPageLayout';
import React, { useState } from 'react';
import { addProjectTypeValidationSchema } from '@config/constants/validation-schemas/project-types/addProjectTypeValidationSchema';
import { CrudTable } from '@components/tables';
import { getProjectTypeErrorMessage } from '@utils/helpers/errorMessage.helpers';
import { ProjectTypeForm } from '@components/forms';
import { ProjectTypeRequestBody } from '@app-types/request-bodies/ProjectTypeRequestBody';
import { Roles } from '@config/constants/security';
import { updateProjectTypeValidationSchema } from '@config/constants/validation-schemas/project-types/updateProjectTypeValidationSchema';
import {
    useAddProjectTypeMutation,
    useDeleteProjectTypeMutation,
    useGetAllProjectTypesQuery,
    useUpdateProjectTypeMutation
} from '@store/api/projectTypes';

export default function ProjectTypesPage() {
    const [openAddProjectTypeDialog, setOpenAddProjectTypeDialog] = useState(false);
    const [updateDialog, setUpdateDialog] = useState<{ open: boolean, entity: ProjectTypeRequestBody | undefined }>({
        open: false,
        entity: undefined
    });

    const [addProjectType] = useAddProjectTypeMutation();
    const [deleteProjectType] = useDeleteProjectTypeMutation();
    const [updateProjectType] = useUpdateProjectTypeMutation();

    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);

    const [validationMessage, setValidationMessage] = useState("");
    const [openValidationSnackbar, setOpenValidationSnackbar] = useState(false);

    const [tablePage, setTablePage] = useState(1);
    const [rowsPerTablePage, setRowsPerTablePage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const { data, isSuccess, isFetching, isError, refetch } = useGetAllProjectTypesQuery({
        page: tablePage,
        itemsPerPage: rowsPerTablePage,
        ...(searchValue !== "" && { search: searchValue })
    });

    /**
     * Shows the confirmation snackbar.
     * @param message The message the confirmation snackbar should display.
     */
    function showConfirmationSnackbar(message: string) {
        setConfirmationMessage(message);
        setOpenConfirmationSnackbar(true);
    }

    React.useEffect(() => setTablePage(1), [searchValue]);

    return (
        <CrudPageLayout
            visibleOverflows
            addDialogTitle={"Projectsoort toevoegen"}
            addDialogForm={
                <ProjectTypeForm
                    closeSelf={() => {
                        setOpenAddProjectTypeDialog(!openAddProjectTypeDialog);
                    }}
                    action={addProjectType}
                    validationSchema={addProjectTypeValidationSchema}
                    buttonText={"Toevoegen"}
                    showConfirmation={() => showConfirmationSnackbar("De projectsoort is succesvol toegevoegd.")}
                    setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                />
            }
            openAddDialog={openAddProjectTypeDialog}
            setOpenAddDialog={setOpenAddProjectTypeDialog}
            openConfirmationSnackbar={openConfirmationSnackbar}
            setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
            confirmationMessage={confirmationMessage}
            openValidationSnackbar={openValidationSnackbar}
            setOpenValidationSnackbar={setOpenValidationSnackbar}
            validationMessage={validationMessage}
            getAllIsError={isError}
            getAllRefetch={refetch}
            pageHeading={"Projectsoorten"}
            addRole={Roles.SuperAdmin}
            setSearchValue={setSearchValue}
        >
            <CrudTable
                visibleOverflows
                data={data}
                isSuccess={isSuccess}
                isFetching={isFetching}
                refetch={refetch}
                page={tablePage}
                rowsPerPage={rowsPerTablePage}
                setPage={setTablePage}
                setRowsPerPage={setRowsPerTablePage}
                setConfirmationMessage={setConfirmationMessage}
                setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                setValidationMessage={setValidationMessage}
                setOpenValidationSnackbar={setOpenValidationSnackbar}
                getErrorMessage={getProjectTypeErrorMessage}
                deleteAction={deleteProjectType}
                columns={[
                    { id: "name", label: "Projectsoort" },
                    { id: "checklistNames", label: "Gekoppelde templates" },
                    { id: "options", label: "Opties", classes: "options-cell" }
                ]}
                deleteConfirmationMessage={"De projectsoort is succesvol verwijderd."}
                updateDialogTitle={"Projectsoort wijzigen"}
                deleteDialogText={"Weet je zeker dat je deze projectsoort wilt verwijderen?"}
                updateForm={
                    <ProjectTypeForm
                        closeSelf={() => setUpdateDialog({ open: false, entity: undefined })}
                        action={updateProjectType}
                        validationSchema={updateProjectTypeValidationSchema}
                        buttonText={"Wijzigen"}
                        projectTypeId={updateDialog.entity ? updateDialog.entity.id : undefined}
                        projectType={updateDialog.entity ?? undefined}
                        showConfirmation={() => showConfirmationSnackbar("De projectsoort is succesvol gewijzigd.")}
                        setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                    />
                }
                updateDialog={updateDialog}
                setUpdateDialog={setUpdateDialog}
            />
        </CrudPageLayout>
    );
}
