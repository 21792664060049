import jwtDecode from 'jwt-decode';
import React, { ReactElement, ReactNode, useState } from 'react';
import userHasAccess from '@utils/helpers/security.helpers';
import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { JwtPayload } from '@app-types/JwtPayload';
import { NavItem } from '@components/layouts/DashboardLayout/NavBar/NavBar';
import { useAppSelector } from '@store/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import './NavButton.styles.scss';

interface Props {
    icon?: ReactElement,
    text: string,
    navPath?: string,
    role?: string
    index: string
    parentIndex?: string,
    children?: ReactNode,
    selectedItems: Array<NavItem>
    setSelectedItems: Function
}

/**
 * Button component that is displayed in the navigation bar.
 * @param icon The icon that should be displayed inside the button. (optional)
 * @param text  The text that should be displayed inside the button.
 * @param navPath The path the button redirects to when it is clicked. (optional)
 * @param role The highest role that has permission to view this navigation item. (optional)
 * @param index The index of the navigation item.
 * @param parentIndex The index of the parent of the navigation item. (optional)
 * @param children The sub navigation items that should be displayed under this navigation item. (optional)
 * @param selectedItems The navigation items that are currently selected.
 * @param setSelectedItems The function that sets the navigation items that are currently selected.
 * @constructor
 */
export default function NavButton({ icon, text, navPath, role, index, parentIndex, children, selectedItems, setSelectedItems }: Props) {
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.token);
    const [open, setOpen] = useState(isSelected());
    const classPrefix = parentIndex ? "sub-" : "";
    let isHidden = false;

    if (token && role) {
        const user = jwtDecode<JwtPayload>(token);
        isHidden = !userHasAccess(user.roles, role);
    }

    /**
     * Checks whether this navigation item is selected.
     */
    function isSelected() {
        let selected = false;

        selectedItems.forEach(item => {
            if (item.id === index) selected = true;
        });

        return selected;
    }

    /**
     * Selects the parent and current navigation item if this is a sub navigation item
     * or only selects the current navigation item if this is not a sub navigation item,
     * opens its collapse if the current item is a parent navigation item
     * and navigates to the given navigation path if it has been set.
     */
    function handleClick() {
        if (children) {
            setSelectedItems([selectedItems[0].id]);
            setOpen(!open);
        } else {
            parentIndex ? setSelectedItems([index, parentIndex]) : setSelectedItems([index]);
        }
        if (navPath) navigate(navPath);
    }

    return (
        <>
            {
                !isHidden &&
                <ListItemButton
                    className={`${classPrefix}nav-button`}
                    selected={isSelected()}
                    onClick={handleClick}
                    disableRipple
                >
                    {
                        icon &&
                        <ListItemIcon className={"nav-icon-container"}>
                            {icon}
                        </ListItemIcon>
                    }
                    <ListItemText className={classPrefix + "nav-text"} primary={text} />
                </ListItemButton>
            }
            {
                (children && !isHidden) &&
                <Collapse className={"nav-collapse"} in={open} timeout={"auto"} unmountOnExit>
                    <div className={"nav-line"}>
                        <List className={"sub-nav-list"} disablePadding>
                            {children}
                        </List>
                    </div>
                </Collapse>
            }
        </>
    );
}
