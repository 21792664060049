export function translateAnswer(answer: string) {
    switch (answer) {
        case "yes":
            return "Ja";
        case "no":
            return "Nee";
        case "n/a":
            return "N.v.t.";
    }
}
