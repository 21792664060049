import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "@store/index";

export const api = createApi({
   baseQuery: fetchBaseQuery({
       baseUrl: process.env.REACT_APP_API_BASE_URL,
       prepareHeaders: (headers, {getState}) => {
           const token = (getState() as RootState).auth.token;
           if(token) {
               headers.set("Authorization", `Bearer ${token}`)
           }

           return headers;
       }
   }),
    tagTypes: [
        "User",
        "Project",
        "ProjectType",
        "Item",
        "Checklist",
        "ChecklistCategory",
        "Check"
    ],
    endpoints: () => ({})
});
