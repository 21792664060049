/**
 * Translates API error message to the feedback message the user should see on the login page.
 * @param message The error message that should be translated.
 */
export function getLoginErrorMessage(message: string) {
    switch (message) {
        case "Invalid credentials.":
            return "Email of wachtwoord is incorrect.";
        default:
            return "Er is iets misgegaan.";
    }
}

/**
 * Translates API error to the feedback message the user should see on user form.
 * @param error The error that should be translated.
 */
export function getUserErrorMessage(error: any): string {
    let message = "Er is iets misgegaan.";
    const code = error.status ? error.status : error.code;

    switch (code) {
        case 400:
            message = "Dit e-mailadres is al in gebruik.";
            break;
        case 401:
            if (error.message === "Expired JWT Token") message = "De sessie is verlopen.";
            break;
        default:
            console.error(error);
            message = "Er is iets misgegaan.";
    }

    return message;
}

/**
 * Translates API error to the feedback message the user should see on project form.
 * @param error The error that should be translated.
 */
export function getProjectErrorMessage(error: any): string {
    let message = "Er is iets misgegaan.";
    const code = error.status ? error.status : error.code;

    switch (code) {
        case 401:
            if (error.message === "Expired JWT Token") message = "De sessie is verlopen.";
            break;
        default:
            message = "Er is iets misgegaan.";
    }

    return message;
}

/**
 * Translates API error to the feedback message the user should see on project form.
 * @param error The error that should be translated.
 */
export function getProjectTypeErrorMessage(error: any): string {
    let message = "Er is iets misgegaan.";
    const code = error.status ? error.status : error.code;

    switch (code) {
        case 401:
            if (error.message === "Expired JWT Token") message = "De sessie is verlopen.";
            break;
        case 409:
            message = "Deze projectsoort kan niet verwijderd worden omdat er nog projecten aan gekoppeld zijn.";
            break;
        default:
            message = "Er is iets misgegaan.";
    }

    return message;
}

/**
 * Translates API error to the feedback message the user should see on item form.
 * @param error The error that should be translated.
 */
export function getItemErrorMessage(error: any): string {
    let message = "Er is iets misgegaan.";
    const code = error.status ? error.status : error.code;

    switch (code) {
        case 401:
            if (error.message === "Expired JWT Token") message = "De sessie is verlopen.";
            break;
        default:
            message = "Er is iets misgegaan.";
    }

    if (message === "Er is iets misgegaan.") message = getChecklistCategoryErrorMessage(error);

    return message;
}

/**
 * Translates API error to the feedback message the user should see on checklist form.
 * @param error The error that should be translated.
 */
export function getChecklistErrorMessage(error: any): string {
    let message = "Er is iets misgegaan.";
    const code = error.status ? error.status : error.code;

    switch (code) {
        case 401:
            if (error.message === "Expired JWT Token") message = "De sessie is verlopen.";
            break;
        case 400:
            message = "Die vraag bestaat al in die categorie.";
            break;
        default:
            message = "Er is iets misgegaan.";
    }

    return message;
}

/**
 * Translates API error to the feedback message the user should see on checklist category form.
 * @param error The error that should be translated.
 */
export function getChecklistCategoryErrorMessage(error: any): string {
    let message = "Er is iets misgegaan.";
    const code = error.status ? error.status : error.code;

    switch (code) {
        case 401:
            if (error.message === "Expired JWT Token") message = "De sessie is verlopen.";
            break;
        case 404:
            message = "Deze checklist bestaat niet.";
            break;
        default:
            message = "Er is iets misgegaan.";
    }

    return message;
}
