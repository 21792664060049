import React, { ReactNode } from 'react';
import {
    Dialog,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { DialogContentGridContainer } from '@components/grids';
import { ReactComponent as CloseIcon } from '@assets/images/icons/plus-solid.svg';

interface Props {
    title: string,
    open: boolean,
    toggleDialog: Function,
    visibleOverflows?: boolean,
    children: ReactNode
}

export default function FormDialog({ title, open, toggleDialog, visibleOverflows, children }: Props) {
    return (
        <Dialog
            data-testid={"formDialog"}
            className={visibleOverflows ? "visible-overflows" : ""}
            open={open}
        >
            <DialogContentGridContainer>
                <Grid container item xs={12}>
                    <Typography variant={"h1"}> {title}</Typography>
                    <IconButton data-testid={"closeDialogButton"} className={"close-dialog-button"} onClick={() => toggleDialog()}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid container item xs={12}>
                    {children}
                </Grid>
            </DialogContentGridContainer>
        </Dialog>
    );
}
