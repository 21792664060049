import * as yup from "yup";

export const updateUserValidationSchema = yup.object({
    firstName: yup.string()
        .max(100, "Voornaam mag maximaal 100 karakters bevatten")
        .required("Voornaam is verplicht"),
    lastName: yup.string()
        .max(100, "Achternaam mag maximaal 100 karakters bevatten")
        .required("Achternaam is verplicht"),
    role: yup.string(),
    email: yup.string()
        .email("Vul een valide emailadres in")
        .required("Email is verplicht"),
    password: yup.string()
        .min(8, "Wachtwoord moet minimaal 8 karakters bevatten")
        .max(255, "Wachtwoord mag maximaal 255 karakters bevatten")
});
