import "./TopBarButtonLayout.styles.scss";
import {Grid} from "@mui/material";
import React, {ReactNode} from "react";

interface Props {
    children: ReactNode
}

/**
 * Container component of the top bar buttons.
 * @param children The buttons that should be displayed in the top bar.
 * @constructor
 */
export default function TopBarButtonLayout({children}: Props) {
    return (
        <Grid container item id={"top-bar-buttons-container"} xs>
            {children}
        </Grid>
    );
}
