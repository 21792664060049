import DashboardLayout from '@components/layouts/DashboardLayout/DashboardLayout';
import React, { useEffect, useState } from 'react';
import { ChecksTableRow } from '@components/tables/rows';
import { FormSnackbar } from '@components/snackbars';
import {
    Grid,
    LinearProgress,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import { PageContentGridItem, PageHeadingGridItem } from '@components/grids';
import { PaginationTable } from '@components/tables';
import { TopBarButton } from '@components/buttons';
import { useGetAllChecksQuery } from '@store/api/checks';
import { useLocation, useNavigate } from 'react-router-dom';
import './ChecksPage.styles.scss';

export default function ChecksPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState("");

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const { data, isSuccess, isFetching } = useGetAllChecksQuery({
        itemsPerPage: rowsPerPage,
        page: page,
        ...(searchValue !== "" && { search: searchValue })
    });

    const columns = [
        { id: "date", label: "Datum" },
        { id: "user", label: "Tester" },
        { id: "project", label: "Project" },
        { id: "projectType", label: "Projectsoort" },
        { id: "checklist", label: "Template" },
        { id: "score", label: "Score" }
    ]

    useEffect(() => {
        if (location.state?.openWithConfirmationMessage) {
            setConfirmationMessage(location.state.openWithConfirmationMessage);
            setOpenConfirmationSnackbar(true);
            window.history.replaceState({}, document.title);
        }
    }, [location.state]);

    useEffect(() => setPage(1), [searchValue]);

    return (
        <DashboardLayout
            topBarButtons={
                <TopBarButton test-id={"addButton"} onClick={() => navigate("/checks/new")}>
                    Nieuw
                </TopBarButton>
            }
            setSearchValue={setSearchValue}
        >
            <Grid container item id={"content"} xs>
                <PageHeadingGridItem>
                    <Typography variant={"h1"}> {"Checks"}</Typography>
                </PageHeadingGridItem>
                <PageContentGridItem>
                    {
                        isSuccess &&
                        <PaginationTable
                            tableClasses={"checks-table"}
                            header={
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell key={column.id}>{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                            }
                            body={
                                !isFetching ?
                                    <>
                                        {
                                            data["hydra:member"].length === 0 ?
                                                <TableRow>
                                                    <TableCell colSpan={columns.length}>
                                                        Er zijn geen checks gevonden.
                                                    </TableCell>
                                                </TableRow> :
                                                data["hydra:member"].map((check: any) => <ChecksTableRow key={check.id} check={check} />)
                                        }
                                    </> :
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <LinearProgress />
                                        </TableCell>
                                    </TableRow>
                            }
                            count={isSuccess ? data["hydra:totalItems"] : 1}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    }
                    {
                        !isFetching && !isSuccess &&
                        "Er is iets misgegaan bij het ophalen van de checks."
                    }
                </PageContentGridItem>
            </Grid>
            <FormSnackbar
                open={openConfirmationSnackbar}
                setOpen={setOpenConfirmationSnackbar}
                message={confirmationMessage}
                severity={"success"}
            />
        </DashboardLayout>
    );
}
