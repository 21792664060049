import React from 'react';
import { convertPhpDateTimeToLocalDateString } from '@utils/helpers/dateTime.helpers';
import { ScoreTag } from '@components/tags';
import {
    Skeleton,
    styled,
    TableCell,
    TableRow
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetChecklistQuery } from '@store/api/checklists';
import { useGetProjectQuery } from '@store/api/projects';
import { useGetProjectTypeQuery } from '@store/api/projectTypes';
import { useGetUserQuery } from '@store/api/users';
import { useNavigate } from 'react-router-dom';

const ChecksTableCell = styled(TableCell)(({ theme }) => ({
    paddingTop: "11px",
    paddingBottom: "11px",
}));

interface Props {
    check: any,
}

export default function ChecksTableRow({ check }: Props) {
    const navigate = useNavigate();
    const { data: user, isSuccess: userIsSuccess } = useGetUserQuery(check.user.split("/").pop());
    const { data: checklist, isSuccess: checklistIsSuccess } = useGetChecklistQuery(check.checklist.split("/").pop());
    const { data: project, isSuccess: projectIsSuccess } = useGetProjectQuery(check.project.split("/").pop());
    const { data: projectType, isSuccess: projectTypeIsSuccess } = useGetProjectTypeQuery(project?.type ?? skipToken);

    return (
        <>
            {
                userIsSuccess && checklistIsSuccess && projectIsSuccess && projectTypeIsSuccess ?
                    <TableRow className={"clickable"} onClick={() => navigate(`/checks/${check.id}/view`)}>
                        <ChecksTableCell key={"date"} width={"10%"}>{convertPhpDateTimeToLocalDateString(check["date"])}</ChecksTableCell>
                        <ChecksTableCell key={"user"}>{user.name}</ChecksTableCell>
                        <ChecksTableCell key={"project"}>{project.name}</ChecksTableCell>
                        <ChecksTableCell key={"projectType"}>{projectType.name}</ChecksTableCell>
                        <ChecksTableCell key={"checklist"}>{checklist.name}</ChecksTableCell>
                        <ChecksTableCell key={"score"}><ScoreTag score={check["score"]} /></ChecksTableCell>
                    </TableRow> :
                    <TableRow>
                        <ChecksTableCell key={"date"}><Skeleton /></ChecksTableCell>
                        <ChecksTableCell key={"user"}><Skeleton /></ChecksTableCell>
                        <ChecksTableCell key={"project"}><Skeleton /></ChecksTableCell>
                        <ChecksTableCell key={"projectType"}><Skeleton /></ChecksTableCell>
                        <ChecksTableCell key={"checklist"}><Skeleton /></ChecksTableCell>
                        <ChecksTableCell key={"score"}><Skeleton /></ChecksTableCell>
                    </TableRow>

            }
        </>
    );
}
