import "./StatusTag.styles.scss";
import React from "react";
import {Box} from "@mui/material";

interface Props {
    text: string,
    variant: "success"|"warning"|"error"
}

/**
 * Component that shows a status tag with text.
 * @param text The text that should be displayed.
 * @param variant The variant/color of the status tag.
 * @constructor
 */
export default function StatusTag({ text, variant }: Props) {
    return (
        <Box className={`status status-${variant}`}>
            {text}
        </Box>
    );
}
