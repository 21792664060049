import CrudPageLayout from '@components/layouts/CrudPageLayout/CrudPageLayout';
import React, { useState } from 'react';
import { addItemValidationSchema } from '@config/constants/validation-schemas/item/addItemValidationSchema';
import { CrudTable } from '@components/tables';
import { ItemForm } from '@components/forms';
import { ItemRequestBody } from '@app-types/request-bodies/ItemRequestBody';
import { Roles } from '@config/constants/security';
import { updateItemValidationSchema } from '@config/constants/validation-schemas/item/updateItemValidationSchema';
import {
    useAddItemMutation,
    useDeleteItemMutation,
    useGetAllItemsQuery,
    useUpdateItemMutation
} from '@store/api/items';

export default function ItemsPage() {
    const [addItem] = useAddItemMutation();
    const [deleteItem] = useDeleteItemMutation();
    const [updateItem] = useUpdateItemMutation();

    const [openAddItemDialog, setOpenAddItemDialog] = useState(false);
    const [updateDialog, setUpdateDialog] = useState<{ open: boolean, entity: ItemRequestBody | undefined }>({
        open: false,
        entity: undefined
    });

    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState("");

    const [tablePage, setTablePage] = useState(1);
    const [rowsPerTablePage, setRowsPerTablePage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const { data, isSuccess, isFetching, isError, refetch } = useGetAllItemsQuery({
        itemsPerPage: rowsPerTablePage,
        page: tablePage,
        ...(searchValue !== "" && { search: searchValue })
    });

    /**
     * Shows the confirmation snackbar.
     * @param message The message the confirmation snackbar should display.
     */
    function showConfirmationSnackbar(message: string) {
        setConfirmationMessage(message);
        setOpenConfirmationSnackbar(true);
    }

    React.useEffect(() => setTablePage(1), [searchValue]);

    return (
        <CrudPageLayout
            addDialogTitle={"Vraag toevoegen"}
            addDialogForm={
                <ItemForm
                    closeSelf={() => setOpenAddItemDialog(!openAddItemDialog)}
                    action={addItem}
                    buttonText={"Toevoegen"}
                    showConfirmation={() => showConfirmationSnackbar("De vraag is succesvol toegevoegd.")}
                    setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                    validationSchema={addItemValidationSchema}
                />
            }
            openAddDialog={openAddItemDialog}
            setOpenAddDialog={setOpenAddItemDialog}
            openConfirmationSnackbar={openConfirmationSnackbar}
            setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
            confirmationMessage={confirmationMessage}
            getAllIsError={isError}
            getAllRefetch={refetch}
            pageHeading={"Vragen"}
            addRole={Roles.SuperAdmin}
            setSearchValue={setSearchValue}
        >
            <CrudTable
                data={data}
                isSuccess={isSuccess}
                isFetching={isFetching}
                refetch={refetch}
                page={tablePage}
                rowsPerPage={rowsPerTablePage}
                setPage={setTablePage}
                setRowsPerPage={setRowsPerTablePage}
                setConfirmationMessage={setConfirmationMessage}
                setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                deleteAction={deleteItem}
                deleteConfirmationMessage={"De vraag is succesvol verwijderd."}
                deleteDialogText={"Weet je zeker dat je deze vraag wilt verwijderen?"}
                columns={[
                    { id: "value", label: "Omschrijving" },
                    { id: "expectedResult", label: "Verwachtte resultaat" },
                    { id: "options", label: "Opties", classes: "options-cell" }
                ]}
                updateDialogTitle={"Vraag wijzigen"}
                updateForm={
                    <ItemForm
                        closeSelf={() => setUpdateDialog({ open: false, entity: undefined })}
                        action={updateItem}
                        buttonText={"Wijzigen"}
                        itemId={updateDialog.entity ? updateDialog.entity.id : undefined}
                        itemValue={updateDialog.entity ? updateDialog.entity.value : undefined}
                        expectedResult={updateDialog.entity ? updateDialog.entity.expectedResult : undefined}
                        showConfirmation={() => showConfirmationSnackbar("De vraag is succesvol gewijzigd.")}
                        setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                        validationSchema={updateItemValidationSchema}
                    />
                }
                updateDialog={updateDialog}
                setUpdateDialog={setUpdateDialog}
            />
        </CrudPageLayout>
    );
}
