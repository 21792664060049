import DashboardLayout from '@components/layouts/DashboardLayout/DashboardLayout';
import React from 'react';
import { CheckForm } from '@components/forms';
import { Grid, Typography } from '@mui/material';
import { PageContentGridItem, PageHeadingGridItem } from '@components/grids';

export default function NewCheckPage() {
    return (
        <DashboardLayout>
            <Grid container item height={"100vh !important"} id={"content"}>
                <PageHeadingGridItem>
                    <Typography variant={"h1"}> {"Nieuwe check"}</Typography>
                </PageHeadingGridItem>
                <PageContentGridItem>
                    <Grid container item spacing={"17px"} xs={12}>
                        <CheckForm />
                    </Grid>
                </PageContentGridItem>
            </Grid>
        </DashboardLayout>
    );
}
