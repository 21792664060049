import CrudPageLayout from '@components/layouts/CrudPageLayout/CrudPageLayout';
import ProjectCard from '@views/ProjectsPage/ProjectCard/ProjectCard';
import React, { useState } from 'react';
import { addProjectValidationSchema } from '@config/constants/validation-schemas/project/addProjectValidationSchema';
import { DeleteDialog } from '@components/dialogs';
import {
    Dialog,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { DialogContentGridContainer } from '@components/grids';
import { logout } from '@store/slices/auth';
import { ProjectDialogState } from '@app-types/StateTypes';
import { ProjectForm } from '@components/forms';
import { ProjectResponseBody } from '@app-types/response-bodies/ProjectResponseBody';
import { ReactComponent as CloseIcon } from '@assets/images/icons/plus-solid.svg';
import { Roles } from '@config/constants/security';
import { updateProjectValidationSchema } from '@config/constants/validation-schemas/project/updateProjectValidationSchema';
import {
    useAddProjectMutation,
    useDeleteProjectMutation,
    useGetAllProjectsQuery,
    useUpdateProjectMutation
} from '@store/api/projects';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * Projects page component using the dashboard layout.
 * @constructor
 */
export default function ProjectsPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [addProject] = useAddProjectMutation();
    const [deleteProject] = useDeleteProjectMutation();
    const [updateProject] = useUpdateProjectMutation();

    const [searchValue, setSearchValue] = useState("");
    const { data, isSuccess, isError, refetch } = useGetAllProjectsQuery({
        ...(searchValue !== "" && { search: searchValue })
    });

    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);

    const [openAddProjectDialog, setOpenAddProjectDialog] = useState(false);
    const [updateDialog, setUpdateDialog] = useState<ProjectDialogState>({
        open: false,
        project: undefined
    });
    const [deleteDialog, setDeleteDialog] = useState<ProjectDialogState>({
        open: false,
        project: undefined
    });

    /**
     * Removes a project when the "yes" button is clicked in the confirmation dialog and closes the dialog.
     */
    async function removeProject() {
        try {
            setOpenConfirmationSnackbar(false);

            if (deleteDialog.project !== undefined) {
                await deleteProject(deleteDialog.project.id).unwrap();
            }

            setDeleteDialog({ open: false, project: undefined });
            showConfirmationSnackbar("Het project is succesvol verwijderd.");
        } catch (error: any) {
            if (error.data.message === "Expired JWT Token") {
                dispatch(logout());
                navigate("/login", { replace: true });
            }
        }
    }

    /**
     * Shows the confirmation snackbar.
     * @param message The message the confirmation snackbar should display.
     */
    function showConfirmationSnackbar(message: string) {
        setConfirmationMessage(message);
        setOpenConfirmationSnackbar(true);
    }

    /**
     * Toggles the update user dialog.
     */
    function toggleUpdateDialog() {
        setUpdateDialog({ open: !updateDialog.open, project: updateDialog.project })
    }

    return (
        <CrudPageLayout
            visibleOverflows={true}
            addDialogTitle={"Project toevoegen"}
            addDialogForm={
                <ProjectForm
                    action={addProject}
                    validationSchema={addProjectValidationSchema}
                    buttonText={"Toevoegen"}
                    closeSelf={() => {
                        if (openAddProjectDialog) refetch();
                        setOpenAddProjectDialog(!openAddProjectDialog);
                    }}
                    showConfirmation={() => showConfirmationSnackbar("Het project is succesvol toegevoegd.")}
                    setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                />
            }
            openAddDialog={openAddProjectDialog}
            setOpenAddDialog={setOpenAddProjectDialog}
            openConfirmationSnackbar={openConfirmationSnackbar}
            setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
            confirmationMessage={confirmationMessage}
            getAllIsError={isError}
            getAllRefetch={refetch}
            pageHeading={"Projecten"}
            addRole={Roles.SuperAdmin}
            setSearchValue={setSearchValue}
        >
            {
                isSuccess &&
                data["hydra:member"].map((project: ProjectResponseBody) => (
                    <ProjectCard
                        key={project.id}
                        project={project}
                        successRate={project.last30DaysAverageScore}
                        deleteProject={() => setDeleteDialog({ open: true, project: project })}
                        updateProject={() => setUpdateDialog({ open: true, project: project })}
                    />
                ))
            }
            {
                isSuccess &&
                data["hydra:member"].length === 0 && isSuccess &&
                <Grid item xs={12}>
                    Er zijn geen projecten gevonden.
                </Grid>
            }
            <DeleteDialog
                open={deleteDialog.open}
                handleClose={() => setDeleteDialog({ open: false, project: undefined })}
                text={"Weet je zeker dat je dit project wilt verwijderen?"}
                deleteAction={() => removeProject()}
            />
            {
                updateDialog.project &&
                <Dialog data-testid={"updateProjectDialog"} className={"visible-overflows"} open={updateDialog.open}>
                    <DialogContentGridContainer>
                        <Grid container item xs={12}>
                            <Typography variant={"h1"}>Project wijzigen</Typography>
                            <IconButton data-testid={"closeUpdateProjectButton"} className={"close-dialog-button"} onClick={toggleUpdateDialog}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid container item xs={12}>
                            <ProjectForm
                                closeSelf={toggleUpdateDialog}
                                action={updateProject}
                                validationSchema={updateProjectValidationSchema}
                                buttonText={"Wijzigen"}
                                project={updateDialog.project}
                                showConfirmation={() => showConfirmationSnackbar("Het project is succesvol gewijzigd.")}
                                setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                            />
                        </Grid>
                    </DialogContentGridContainer>
                </Dialog>
            }
        </CrudPageLayout>
    );
}
