import {logout} from "@store/slices/auth";
import {getProjectErrorMessage} from "@utils/helpers/errorMessage.helpers";
import {useFormik} from "formik";
import {AnyObjectSchema} from "yup";
import {ProjectRequestBody} from "@app-types/request-bodies/ProjectRequestBody";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Autocomplete, Button, Grid, Skeleton, TextField} from "@mui/material";
import {FormSnackbar} from "@components/snackbars";
import {useGetAllProjectTypesQuery} from "@store/api/projectTypes";
import {ProjectResponseBody} from "@app-types/response-bodies/ProjectResponseBody";
import {AutocompleteOption} from "@app-types/form-value-types/AutocompleteOption";
import {ProjectFormValue} from "@app-types/form-value-types/ProjectFormValue";
import {logoutIfInvalidToken} from "@utils/helpers/security.helpers";
import {useAppSelector} from "@store/hooks/hooks";

/**
 * @param closeSelf The function that closes the dialog.
 * @param projectName Default value for the name input field. (optional)
 * @param action The mutation action that should be executed on submit.
 * @param validationSchema The yup form validation schema that the form should use.
 * @param buttonText The text that the submit button should have.
 * @param projectId The id of the project that needs to be updated (optional)
 * @param showConfirmation Function that shows the confirmation snackbar with the correct message.
 */
interface Props {
    closeSelf: Function,
    project?: ProjectResponseBody,
    action: Function,
    validationSchema: AnyObjectSchema,
    buttonText: string,
    showConfirmation: Function,
    setOpenConfirmationSnackbar: Function
}

/**
 * Project form component that is used for adding and updating users.
 * @constructor
 */
export default function ProjectForm(props: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {data: projectTypes, isSuccess: projectTypesIsSuccess} = useGetAllProjectTypesQuery({});
    const [types, setTypes] = useState<AutocompleteOption[]>([]);
    const token = useAppSelector((state) => state.auth.token);

    useEffect(() => {
        logoutIfInvalidToken(token, dispatch, navigate);
    });

    /**
     * Initialization of the formik form.
     */
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: props.project ? props.project.name : "",
            type: null
        },
        validationSchema: props.validationSchema,
        onSubmit: async (values: ProjectFormValue) => {
            try {
                props.setOpenConfirmationSnackbar(false);

                let body: ProjectRequestBody = {};
                if (values.name) body.name = values.name;
                if (values.type) body.typeId = values.type.id;

                if(props.project !== undefined) {
                    await props.action({id: props.project.id, body: body}).unwrap();
                } else {
                    await props.action(body).unwrap();
                }

                props.showConfirmation();
                props.closeSelf();
            } catch (error: any) {
                if (error.data.message === "Expired JWT Token") {
                    dispatch(logout());
                    navigate("/login", {replace: true});
                }

                setErrorMessage(getProjectErrorMessage(error));
                setOpenSnackbar(true);
            }
        }
    });

    useEffect(() => {
        if (projectTypesIsSuccess) {
            let typeNames: AutocompleteOption[] = [];
            projectTypes["hydra:member"].forEach((type: any) => {
                typeNames.push({
                    label: type.name,
                    id: type.id
                })
            })
            setTypes(typeNames);

            if (props.project) {
                const splitTypeUri = props.project.type.split("/");
                const typeId = +splitTypeUri[splitTypeUri.length-1];

                const defaultType = typeNames.find((type) => type.id === typeId);
                if (defaultType) {
                    formik.setFieldValue("type", defaultType);
                }
            }
        }
    }, [projectTypesIsSuccess, projectTypes, props.project]);

    return (
        <>
            {
                projectTypesIsSuccess ?
                <form data-testid={"form"} onSubmit={formik.handleSubmit} noValidate>
                    <TextField
                        id={"projectName"}
                        name={"name"}
                        label={"Naam"}
                        type={"text"}
                        margin={"dense"}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        inputProps={{
                            "data-testid": "projectName"
                        }}
                    />
                    <Autocomplete
                        data-testid={"projectTypeAC"}
                        multiple={false}
                        disablePortal
                        autoComplete
                        value={formik.values.type}
                        onChange={(e, value) => formik.setFieldValue("type", value)}
                        onBlur={formik.handleBlur}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                id={"type"}
                                name={"type"}
                                label="Projectsoort"
                                margin={"dense"}
                                error={formik.touched.type && Boolean(formik.errors.type)}
                                helperText={formik.touched.type && formik.errors.type}
                                inputProps={{
                                    ...params.inputProps,
                                    "data-testid": "projectType"
                                }}
                            />}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <span>{option.label}</span>
                            </li>
                        )}
                        options={types}
                    />
                    <Button data-testid={"submit"} type={"submit"}>{props.buttonText}</Button>
                    <FormSnackbar
                        open={openSnackbar}
                        setOpen={setOpenSnackbar}
                        message={errorMessage}
                        severity={"error"}
                    />
                </form> :
                <Grid container spacing={1}>
                    <Grid container item xs={12}>
                        <Skeleton variant={"rectangular"} height={53} width={355}/>
                    </Grid>
                    <Grid container item xs={12}>
                        <Skeleton variant={"rectangular"} height={53} width={355}/>
                    </Grid>
                    <Grid container item xs={12}>
                        <Skeleton variant={"rectangular"} height={53} width={355}/>
                    </Grid>
                </Grid>
            }
        </>
    );
}
