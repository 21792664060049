import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import {useFormik} from "formik";
import {getUserErrorMessage} from "@utils/helpers/errorMessage.helpers";
import {useDispatch} from "react-redux";
import {logout, setMe} from "@store/slices/auth";
import {useNavigate} from "react-router-dom";
import {Roles} from "@config/constants/security";
import {AnyObjectSchema} from "yup";
import {UserRequestBody} from "@app-types/request-bodies/UserRequestBody";
import {FormSnackbar} from "@components/snackbars";
import {useAppSelector} from "@store/hooks/hooks";
import {useLazyGetMeQuery} from "@store/api/users";

/**
 * @param closeSelf The function that closes the dialog.
 * @param firstName Default value for the firstName input field. (optional)
 * @param lastName Default value for the lastName input field. (optional)
 * @param email Default value for the email input field. (optional)
 * @param role Default value for the role input field. (optional)
 * @param action The mutation action that should be executed on submit.
 * @param validationSchema The yup form validation schema that the form should use.
 * @param buttonText The text that the submit button should have.
 * @param userId The id of the user that needs to be updated (optional)
 * @param showConfirmation Function that shows the confirmation snackbar with the correct message.
 */
interface Props {
    closeSelf: Function,
    firstName?: string,
    lastName?: string,
    email?: string,
    role?: Roles,
    action: Function,
    validationSchema: AnyObjectSchema,
    buttonText: string,
    userId?: any,
    showConfirmation: Function,
    setOpenConfirmationSnackbar: Function
}

/**
 * User form component that is used for adding and updating users.
 * @constructor
 */
export default function UserForm(props: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [trigger] = useLazyGetMeQuery();
    const me = useAppSelector((state) => state.auth.me);

    /**
     * Initialization of the formik form.
     */
    const formik = useFormik({
        initialValues: {
            firstName: props.firstName ?? "",
            lastName: props.lastName ?? "",
            role: props.role ?? Roles.User,
            email: props.email ?? "",
            password: ""
        },
        validationSchema: props.validationSchema,
        onSubmit: async (values: UserRequestBody) => {
            try {
                props.setOpenConfirmationSnackbar(false);

                let body = {...values};
                if (body.password === "") delete body.password;

                if(props.userId !== undefined) {
                    await props.action({id: props.userId, body: body}).unwrap();

                    if (me?.id === props.userId) {
                        if (body.email !== me?.email || body.password !== undefined || body.role !== me?.role) {
                            dispatch(logout());
                            navigate("/login", {replace: true});
                        }

                        const getMeResponse = await trigger({}, false).unwrap();
                        dispatch(setMe(getMeResponse));
                    }
                } else {
                    await props.action(body).unwrap();
                }

                props.showConfirmation();
                props.closeSelf();
            } catch (error: any) {
                if (error.data.message === "Expired JWT Token") {
                    dispatch(logout());
                    navigate("/login", {replace: true});
                }

                setErrorMessage(getUserErrorMessage(error));
                setOpenSnackbar(true);
            }
        }
    });

    return (
        <form data-testid={"form"} onSubmit={formik.handleSubmit} noValidate>
            <TextField
                id={"firstName"}
                name={"firstName"}
                label={"Voornaam"}
                type={"text"}
                margin={"dense"}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                inputProps={{
                    "data-testid": "firstName"
                }}
            />
            <TextField
                id={"lastName"}
                name={"lastName"}
                label={"Achternaam"}
                type={"text"}
                margin={"dense"}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
                inputProps={{
                    "data-testid": "lastName"
                }}
            />
            <TextField
                id={"email"}
                name={"email"}
                label={"E-mailadres"}
                type={"email"}
                margin={"dense"}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                inputProps={{
                    "data-testid": "email"
                }}
            />
            <TextField
                id={"password"}
                name={"password"}
                label={"Wachtwoord"}
                type={"password"}
                margin={"dense"}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                inputProps={{
                    "data-testid": "password"
                }}
            />
            <FormControl className={"radio"} fullWidth>
                <FormLabel id="role-radio-buttons-group-label">Rol</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="role-radio-buttons-group-label"
                    name="role"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                >
                    <FormControlLabel
                        data-testid={"roleUser"}
                        value="ROLE_USER"
                        control={<Radio color={"secondary"} size={"small"} />}
                        label="Gebruiker"
                    />
                    <FormControlLabel
                        data-testid={"roleAdmin"}
                        value="ROLE_ADMIN"
                        control={<Radio color={"secondary"} size={"small"} />}
                        label="Admin"
                    />
                    <FormControlLabel
                        data-testid={"roleSuperAdmin"}
                        value="ROLE_SUPER_ADMIN"
                        control={<Radio color={"secondary"} size={"small"} />}
                        label="Super Admin"
                    />
                </RadioGroup>
            </FormControl>
            <Button data-testid={"submit"} type={"submit"}>{props.buttonText}</Button>
            <FormSnackbar
                open={openSnackbar}
                setOpen={setOpenSnackbar}
                message={errorMessage}
                severity={"error"}
            />
        </form>
    );
}
