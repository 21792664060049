import React, { ReactElement, useState } from 'react';
import {
    Box,
    Dialog,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    Skeleton,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import { DeleteDialog } from '@components/dialogs';
import { DialogContentGridContainer } from '@components/grids';
import { EntityDialogState } from '@app-types/StateTypes';
import { logout } from '@store/slices/auth';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { PaginationTable } from '@components/tables';
import { ReactComponent as EditIcon } from '@assets/images/icons/pen-to-square-regular.svg';
import { ReactComponent as TrashIcon } from '@assets/images/icons/trash-regular.svg';
import { ReactComponent as CloseIcon } from '@assets/images/icons/plus-solid.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './CrudTable.styles.scss';

/**
 * @param data The data of the get all users query.
 * @param isSuccess Whether the get all users query was a success.
 * @param refetch The function that refetches the users.
 * @param page The state that stores the page of the table that is displayed.
 * @param rowsPerPage The state that stores the amount of users that should be displayed on a page.
 * @param setPage The function that sets the page of the table that should be displayed.
 * @param setRowsPerPage The function that sets the amount of users that should be displayed on a page.
 * @param setConfirmationMessage The function that sets the state that shows the confirmation message.
 * @param setOpenConfirmationSnackbar The function that sets the state that shows whether the confirmation snackbar is open.
 * @param deleteAction The function that deletes the entity.
 * @param columns The columns that should be displayed in the table.
 * @param deleteConfirmationMessage The message that should be displayed when an entity has been deleted.
 * @param updateDialogTitle The title that should be displayed on the update dialog.
 * @param deleteDialogText The text that should be displayed on the delete dialog.
 * @param updateForm The form that should be used for updating entities.
 * @param updateDialog The state that shows whether the dialog is open and what the selected entity is.
 * @param setUpdateDialog The function that sets the updateDialog state.
 */
interface Props {
    data: any,
    isSuccess: boolean,
    isFetching: boolean,
    refetch: Function,
    page: number,
    rowsPerPage: number,
    setPage: Function,
    setRowsPerPage: Function,
    setConfirmationMessage: Function,
    setOpenConfirmationSnackbar: Function,
    setValidationMessage?: Function,
    setOpenValidationSnackbar?: Function,
    getErrorMessage?: Function,
    deleteAction: MutationTrigger<any>,
    deleteConfirmationMessage: string,
    deleteDialogText: string,
    columns: Column[],
    updateDialogTitle: string,
    updateForm: ReactElement,
    updateDialog: EntityDialogState,
    setUpdateDialog: Function,
    onRowClick?: Function,
    visibleOverflows?: boolean
}

interface Column {
    id: string,
    label: string,
    classes?: string
}

export default function CrudTable(props: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [deleteDialog, setDeleteDialog] = useState({
        open: false,
        entity: undefined
    });

    /**
     * Shows the confirmation snackbar.
     * @param message The message the confirmation snackbar should display.
     */
    function showConfirmationSnackbar(message: string) {
        props.setConfirmationMessage(message);
        props.setOpenConfirmationSnackbar(true);
    }

    /**
     * Shows the validation snackbar.
     * @param message The message the validation snackbar should display.
     */
    function showValidationSnackbar(message: string) {
        if (props.setValidationMessage && props.setOpenValidationSnackbar) {
            props.setValidationMessage(message);
            props.setOpenValidationSnackbar(true);
        }
    }

    /**
     * Removes an entity when the "yes" button is clicked in the confirmation dialog and closes the dialog.
     */
    async function removeEntity() {
        try {
            props.setOpenConfirmationSnackbar(false);
            if (props.getErrorMessage && props.setOpenValidationSnackbar) props.setOpenValidationSnackbar(false);

            if (deleteDialog.entity === undefined) return;

            await props.deleteAction(deleteDialog.entity["id"]).unwrap();

            if (props.data["hydra:member"].length === 1 && props.page > 1 && props.data["hydra:member"][0].id === deleteDialog.entity["id"]) {
                props.setPage(props.page - 1);
            } else {
                props.refetch();
            }

            setDeleteDialog({ open: false, entity: undefined });
            showConfirmationSnackbar(props.deleteConfirmationMessage);
        } catch (error: any) {
            if (error.data?.message === "Expired JWT Token") {
                dispatch(logout());
                navigate("/login", { replace: true });
            }

            if (props.getErrorMessage && props.setOpenValidationSnackbar) {
                setDeleteDialog({ open: false, entity: undefined });
                showValidationSnackbar(props.getErrorMessage(error));
            }
        }
    }

    /**
     * Toggles the update dialog.
     */
    function toggleUpdateDialog() {
        props.setUpdateDialog({ open: !props.updateDialog.open, entity: props.updateDialog.entity })
    }

    /**
     * Handles the click on a row.
     * @param entityId The id of the entity that was clicked.
     */
    function handleRowClick(entityId: number) {
        if (props.onRowClick) props.onRowClick(entityId);
    }


    return (
        <Grid container item xs={12}>
            {
                props.isSuccess &&
                <PaginationTable
                    header={
                        <TableRow>
                            {
                                props.columns.map((column) => (
                                    <TableCell key={column.id} className={column.classes}>{column.label}</TableCell>
                                ))
                            }
                        </TableRow>
                    }
                    body={
                        <>
                            {
                                !props.isFetching ?
                                    <>
                                        {
                                            props.data["hydra:member"].length === 0 ?
                                                <TableRow>
                                                    <TableCell colSpan={props.columns.length}>
                                                        Er zijn geen resultaten gevonden.
                                                    </TableCell>
                                                </TableRow> :
                                                props.data["hydra:member"].map((entity: any) => (
                                                    <TableRow
                                                        className={props.onRowClick ? "clickable" : ""}
                                                        key={entity["id"]}
                                                        onClick={() => handleRowClick(entity["id"])}
                                                    >
                                                        {
                                                            props.columns.map((column) => (
                                                                column.id === "options" ?
                                                                    <TableCell key={column.id} className={"options-cell"}>
                                                                        <Grid container item className={"option-icons-container"} xs>
                                                                            <Grid container item justifyContent={"start"} xs={6}>
                                                                                <Link underline={"none"} component={"button"} onClick={(event) => {
                                                                                    event.stopPropagation();
                                                                                    props.setUpdateDialog({ open: true, entity: entity })
                                                                                }
                                                                                }>
                                                                                    <Box className={"table-option-icon"}>
                                                                                        <EditIcon />
                                                                                        <span className={"table-option-text"}>Wijzigen</span>
                                                                                    </Box>
                                                                                </Link>
                                                                            </Grid>
                                                                            <Grid container item justifyContent={"end"} xs={6}>
                                                                                <Link underline={"none"} component={"button"} onClick={(event) => {
                                                                                    event.stopPropagation();
                                                                                    setDeleteDialog({ open: true, entity: entity })
                                                                                }
                                                                                }>
                                                                                    <Box className={"table-option-icon"}>
                                                                                        <TrashIcon />
                                                                                        <span className={"table-option-text"}>Verwijderen</span>
                                                                                    </Box>
                                                                                </Link>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell> :
                                                                    <TableCell key={column.id} className={column.classes}>
                                                                        {
                                                                            typeof entity[column.id] === "string" && entity[column.id].includes("\r\n") ?
                                                                                entity[column.id].split("\r\n").map((item: any, index: number) => (
                                                                                    <span key={index}>
                                                                                        {item}
                                                                                        {index < entity[column.id].split("\r\n").length - 1 && <br />}
                                                                                    </span>
                                                                                )) : entity[column.id]
                                                                        }
                                                                    </TableCell>
                                                            ))
                                                        }
                                                    </TableRow>
                                                ))
                                        }
                                    </> :
                                    <TableRow>
                                        <TableCell colSpan={props.columns.length}>
                                            <LinearProgress />
                                        </TableCell>
                                    </TableRow>
                            }
                            {
                                !props.isSuccess &&
                                Array.from({ length: 10 }, (v, i) =>
                                    <TableRow key={i}>
                                        {
                                            props.columns.map((column) => (
                                                <TableCell key={column.id} className={column.classes}>
                                                    <Skeleton variant={"text"} />
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                )
                            }
                            {
                                props.updateDialog.entity &&
                                <Dialog
                                    data-testid={"updateDialog"}
                                    className={props.visibleOverflows ? "visible-overflows" : ""}
                                    open={props.updateDialog.open}
                                >
                                    <DialogContentGridContainer>
                                        <Grid container item xs={12}>
                                            <Typography variant={"h1"}> {props.updateDialogTitle}</Typography>
                                            <IconButton data-testid={"closeUpdateButton"} className={"close-dialog-button"} onClick={toggleUpdateDialog}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            {props.updateForm}
                                        </Grid>
                                    </DialogContentGridContainer>
                                </Dialog>
                            }
                            <DeleteDialog
                                open={deleteDialog.open}
                                handleClose={() => setDeleteDialog({ open: false, entity: undefined })}
                                text={props.deleteDialogText}
                                deleteAction={() => removeEntity()}
                            />
                        </>
                    }
                    count={props.isSuccess ? props.data["hydra:totalItems"] : 1}
                    page={props.page}
                    setPage={props.setPage}
                    rowsPerPage={props.rowsPerPage}
                    setRowsPerPage={props.setRowsPerPage}
                />
            }
        </Grid>
    );
}

