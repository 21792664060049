import DashboardLayout from "@components/layouts/DashboardLayout/DashboardLayout";
import React, { useEffect, useState } from "react";
import { addChecklistCategoryValidationSchema } from "@config/constants/validation-schemas/checklist-category/addChecklistCategoryValidationSchema";
import { addItemValidationSchema } from "@config/constants/validation-schemas/item/addItemValidationSchema";
import { Button, Grid, Link, Skeleton, Typography } from "@mui/material";
import { ChecklistCategoryForm, ItemForm } from "@components/forms";
import { DeleteDialog, FormDialog } from "@components/dialogs";
import { EntityDialogState } from "@app-types/StateTypes";
import { FormSnackbar } from "@components/snackbars";
import { ItemsTable } from "@components/tables";
import { ItemTypes } from "@config/constants/ItemTypes";
import { logout } from "@store/slices/auth";
import { logoutIfInvalidToken } from "@utils/helpers/security.helpers";
import { PageHeadingGridItem } from "@components/grids";
import { ReactComponent as EditIcon } from "@assets/images/icons/pen-to-square-regular.svg";
import { ReactComponent as TrashIcon } from "@assets/images/icons/trash-regular.svg";
import { updateChecklistCategoryValidationSchema } from "@config/constants/validation-schemas/checklist-category/updateChecklistCategoryValidationSchema";
import {
	useAddChecklistCategoryMutation,
	useDeleteChecklistCategoryMutation,
	useDeleteItemFromCategoryMutation,
	useUpdateChecklistCategoryMutation,
} from "@store/api/checklistCategory";
import { useAddItemMutation, useGetAllItemsQuery } from "@store/api/items";
import { useAppSelector } from "@store/hooks/hooks";
import { useDispatch } from "react-redux";
import { useDrop } from "react-dnd";
import {
	useGetChecklistCategoriesQuery,
	useGetChecklistQuery,
} from "@store/api/checklists";
import { useNavigate } from "react-router-dom";
import "./TemplateItemsPage.styles.scss";

/**
 * TemplateItemsPage component that renders the template items page.
 * @constructor
 */
export default function TemplateItemsPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const checklistId = window.location.pathname.split("/").pop();
	const [addItem] = useAddItemMutation();
	const [addCategory] = useAddChecklistCategoryMutation();
	const [updateCategory] = useUpdateChecklistCategoryMutation();
	const [deleteCategory] = useDeleteChecklistCategoryMutation();
	const [deleteItemFromCategory] = useDeleteItemFromCategoryMutation();
	const {
		data: checklist,
		isSuccess: checklistIsSuccess,
		isError: checklistIsError,
	} = useGetChecklistQuery(checklistId, {});
	const {
		data: categories,
		isSuccess: categoriesIsSuccess,
		isError: categoriesIsError,
	} = useGetChecklistCategoriesQuery(checklistId, {});
	const {
		data: items,
		isSuccess: itemsIsSuccess,
		isError: itemsIsError,
	} = useGetAllItemsQuery({
		pagination: false,
	});
	const [
		openAddChecklistCategoryDialog,
		setOpenAddChecklistCategoryDialog,
	] = useState(false);
	const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(
		false
	);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [openValidationSnackbar, setOpenValidationSnackbar] = useState(false);
	const [validationMessage, setValidationMessage] = useState("");
	const token = useAppSelector((state) => state.auth.token);
	const [
		updateChecklistCategoryDialog,
		setUpdateChecklistCategoryDialog,
	] = useState<EntityDialogState>({
		open: false,
		entity: undefined,
	});
	const [
		deleteChecklistCategoryDialog,
		setDeleteChecklistCategoryDialog,
	] = useState<EntityDialogState>({
		open: false,
		entity: undefined,
	});
	const [addItemToCategoryDialog, setAddItemToCategoryDialog] = useState<
		EntityDialogState
	>({
		open: false,
		entity: undefined,
	});
	const [{ canDrop }, drop] = useDrop(() => ({
		accept: ItemTypes.CATEGORY_ITEM,
		drop: async (item: { id: number; categoryId: number }) => handleDrop(item),
		collect: (monitor) => ({
			canDrop: monitor.canDrop(),
		}),
	}));

	/**
	 * Check if the token is valid, if not, log the user out.
	 */
	useEffect(() => {
		if (checklistIsError || categoriesIsError || itemsIsError) {
			logoutIfInvalidToken(token, dispatch, navigate);
		}
	});

	/**
	 * Show the validation snackbar.
	 * @param message The message to show in the snackbar.
	 */
	function showValidationSnackbar(message: string) {
		setValidationMessage(message);
		setOpenValidationSnackbar(true);
	}

	/**
	 * Shows the confirmation snackbar.
	 * @param message The message the confirmation snackbar should display.
	 */
	function showConfirmationSnackbar(message: string) {
		setConfirmationMessage(message);
		setOpenConfirmationSnackbar(true);
	}

	/**
	 * Removes a checklist category when the "yes" button is clicked in the confirmation dialog and closes the dialog.
	 */
	async function removeChecklistCategory() {
		try {
			if (deleteChecklistCategoryDialog.entity === undefined) return;
			await deleteCategory(deleteChecklistCategoryDialog.entity.id).unwrap();

			setDeleteChecklistCategoryDialog({ open: false, entity: undefined });
		} catch (error: any) {
			if (error.data.message === "Expired JWT Token") {
				dispatch(logout());
				navigate("/login", { replace: true });
			}

			if (error.status === 409) {
				setDeleteChecklistCategoryDialog({ open: false, entity: undefined });
				showValidationSnackbar(
					"Een checklist moet minimaal 1 categorie hebben."
				);
			}
		}
	}

	/**
	 * Handles the drop of a category item on the drop box.
	 * @param item The item that was dropped.
	 */
	async function handleDrop(item: any) {
		try {
			await deleteItemFromCategory(item.id).unwrap();
		} catch (error: any) {
			if (error.data?.message === "Expired JWT Token") {
				dispatch(logout());
				navigate("/login", { replace: true });
			}
		}
	}

	return (
		<DashboardLayout>
			<Grid container item>
				<Grid container item className={"items-container"} xs={12} md={6}>
					<PageHeadingGridItem>
						<Grid container item>
							<Grid container item className={"header-container"} xs>
								<Typography variant={"h1"}>Alle vragen</Typography>
							</Grid>
							<Grid container item className={"button-container"} xs={3.5}>
								<Button
									data-testid={"addItemCategoryButton"}
									className={"add-category-button"}
									fullWidth={false}
									disabled
									sx={{ visibility: "hidden" }}
								>
									Nieuwe categorie
								</Button>
							</Grid>
						</Grid>
						{canDrop ? (
							<Grid
								container
								item
								className={"drop-zone-error"}
								ref={drop}
								height={"77vh !important"}
								xs
							>
								<span>Sleep je vraag hierheen om te verwijderen</span>
							</Grid>
						) : (
							<Grid container item>
								{checklistIsSuccess && categoriesIsSuccess && itemsIsSuccess ? (
									<Grid container item>
										<ItemsTable
											itemEntities={items["hydra:member"]}
											showValidationSnackbar={showValidationSnackbar}
										/>
									</Grid>
								) : (
									<Skeleton variant={"rectangular"} height={"80vh"} />
								)}
							</Grid>
						)}
					</PageHeadingGridItem>
				</Grid>
				<Grid container item className={"checklist-container"} xs={12} md={6}>
					<PageHeadingGridItem>
						{checklistIsSuccess && categoriesIsSuccess && itemsIsSuccess ? (
							<Grid container item>
								<Grid container item>
									<Grid container item className={"header-container"} xs>
										<Typography variant={"h1"}> {checklist.name}</Typography>
									</Grid>
									<Grid container item className={"button-container"} xs={3.5}>
										<Button
											data-testid={"addChecklistCategoryButton"}
											className={"add-category-button"}
											fullWidth={false}
											onClick={() => setOpenAddChecklistCategoryDialog(true)}
										>
											Nieuwe categorie
										</Button>
									</Grid>
								</Grid>
								{categories["hydra:member"].map((category: any) => (
									<Grid key={category.id} container item>
										<Grid item pt={"13px"}>
											<Typography variant={"h3"}>{category.name}</Typography>
										</Grid>
										<Grid item ml={"10px"}>
											<Grid
												container
												item
												className={"category-icon-container"}
											>
												<Grid item>
													<Link
														data-testid={"updateChecklistCategoryButton"}
														underline={"none"}
														component={"button"}
														className={"edit-button"}
														onClick={() =>
															setUpdateChecklistCategoryDialog({
																open: !updateChecklistCategoryDialog.open,
																entity: category,
															})
														}
													>
														<EditIcon />
													</Link>
												</Grid>
											</Grid>
										</Grid>
										{categories["hydra:member"].length > 1 && (
											<Grid item ml={"5px"}>
												<Grid
													container
													item
													className={"category-icon-container"}
												>
													<Grid item>
														<Link
															data-testid={"deleteChecklistCategoryButton"}
															underline={"none"}
															component={"button"}
															className={"delete-button"}
															onClick={() =>
																setDeleteChecklistCategoryDialog({
																	open: true,
																	entity: category,
																})
															}
														>
															<TrashIcon />
														</Link>
													</Grid>
												</Grid>
											</Grid>
										)}
										<Grid
											container
											item
											className={"question-link-container"}
											xs
										>
											<Link
												underline={"none"}
												onClick={() =>
													setAddItemToCategoryDialog({
														open: true,
														entity: category,
													})
												}
											>
												Vraag toevoegen +
											</Link>
										</Grid>
										<ItemsTable
											category={category}
											showValidationSnackbar={showValidationSnackbar}
										/>
									</Grid>
								))}
							</Grid>
						) : (
							<>
								<Typography variant={"h1"}>
									<Skeleton variant={"text"} />
								</Typography>
								<Typography variant={"h3"}>
									<Skeleton variant={"text"} />
								</Typography>
								<Skeleton variant={"rectangular"} height={"30vh"} />
								<Typography variant={"h3"}>
									<Skeleton variant={"text"} />
								</Typography>
								<Skeleton variant={"rectangular"} height={"30vh"} />
							</>
						)}

						<DeleteDialog
							open={deleteChecklistCategoryDialog.open}
							handleClose={() =>
								setDeleteChecklistCategoryDialog({
									open: false,
									entity: deleteChecklistCategoryDialog.entity,
								})
							}
							text={"Weet je zeker dat je deze categorie wilt verwijderen?"}
							deleteAction={() => removeChecklistCategory()}
						/>
					</PageHeadingGridItem>
				</Grid>
			</Grid>

			<FormSnackbar
				open={openConfirmationSnackbar}
				setOpen={setOpenConfirmationSnackbar}
				message={confirmationMessage}
				severity={"success"}
			/>

			<FormSnackbar
				open={openValidationSnackbar}
				setOpen={setOpenValidationSnackbar}
				message={validationMessage}
				severity={"error"}
			/>

			<FormDialog
				title={"Categorie toevoegen"}
				open={openAddChecklistCategoryDialog}
				toggleDialog={() =>
					setOpenAddChecklistCategoryDialog(!openAddChecklistCategoryDialog)
				}
			>
				<ChecklistCategoryForm
					closeDialog={() => setOpenAddChecklistCategoryDialog(false)}
					action={addCategory}
					validationSchema={addChecklistCategoryValidationSchema}
					buttonText={"Toevoegen"}
					showConfirmation={() =>
						showConfirmationSnackbar("De categorie is succesvol toegevoegd.")
					}
					setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
					checklistId={checklistId}
				/>
			</FormDialog>

			{updateChecklistCategoryDialog.entity && (
				<FormDialog
					title={"Categorie wijzigen"}
					open={updateChecklistCategoryDialog.open}
					toggleDialog={() =>
						setUpdateChecklistCategoryDialog({
							open: !updateChecklistCategoryDialog.open,
							entity: updateChecklistCategoryDialog.entity,
						})
					}
				>
					<ChecklistCategoryForm
						closeDialog={() =>
							setUpdateChecklistCategoryDialog({
								open: !updateChecklistCategoryDialog.open,
								entity: updateChecklistCategoryDialog.entity,
							})
						}
						action={updateCategory}
						validationSchema={updateChecklistCategoryValidationSchema}
						buttonText={"Wijzigen"}
						showConfirmation={() => {}}
						setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
						category={updateChecklistCategoryDialog.entity}
					/>
				</FormDialog>
			)}

			<FormDialog
				title={`Vraag toevoegen (${addItemToCategoryDialog.entity?.name})`}
				open={addItemToCategoryDialog.open}
				toggleDialog={() =>
					setAddItemToCategoryDialog({
						open: !addItemToCategoryDialog.open,
						entity: addItemToCategoryDialog.entity,
					})
				}
			>
				<ItemForm
					closeSelf={() =>
						setAddItemToCategoryDialog({
							open: !addItemToCategoryDialog.open,
							entity: addItemToCategoryDialog.entity,
						})
					}
					action={addItem}
					buttonText={"Toevoegen"}
					showConfirmation={() =>
						showConfirmationSnackbar("De vraag is succesvol toegevoegd.")
					}
					setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
					validationSchema={addItemValidationSchema}
					category={addItemToCategoryDialog.entity}
				/>
			</FormDialog>
		</DashboardLayout>
	);
}
