import DashboardLayout from '@components/layouts/DashboardLayout/DashboardLayout';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import userHasAccess, { logoutIfInvalidToken } from '@utils/helpers/security.helpers';
import {
    Dialog,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { DialogContentGridContainer } from '@components/grids';
import { FormSnackbar } from '@components/snackbars';
import { PageContentGridItem, PageHeadingGridItem } from '@components/grids';
import { ReactComponent as CloseIcon } from '@assets/images/icons/plus-solid.svg';
import { Roles } from '@config/constants/security';
import { TopBarButton } from '@components/buttons';
import { useAppSelector } from '@store/hooks/hooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * @param children The page content children.
 * @param addDialogTitle The title that displays on the add dialog form.
 * @param addDialogForm The dialog form that should display when the "Nieuw" button has been clicked.
 * @param openAddDialog The state that shows whether the add dialog is open.
 * @param setOpenAddDialog The function that sets the openAddDialog state.
 * @param openSnackbar The state that shows whether the confirmation snackbar is open.
 * @param setOpenSnackbar The function that sets the openSnackbar state.
 * @param confirmationMessage The state that shows the confirmation message that should display when the snackbar is opened.
 * @param getAllIsError Whether an error has occured in the get collection query.
 * @param getAllRefetch The refetch function of the get collection query.
 * @param pageHeading The text that should display in the page heading.
 */
interface Props {
    children: ReactNode,
    visibleOverflows?: boolean,
    addDialogTitle: string,
    addDialogForm: ReactElement,
    openAddDialog: boolean,
    setOpenAddDialog: Function,
    openConfirmationSnackbar: boolean,
    setOpenConfirmationSnackbar: Function,
    confirmationMessage: string,
    openValidationSnackbar?: boolean,
    setOpenValidationSnackbar?: Function,
    validationMessage?: string,
    getAllIsError: boolean,
    getAllRefetch: Function,
    pageHeading: string,
    addRole: Roles
    setSearchValue?: Function
}

/**
 * Component that defines the base layout of crud pages.
 * @param props The component properties.
 * @constructor
 */
export default function CrudPageLayout(props: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.token);
    const me = useAppSelector(state => state.auth.me);

    useEffect(() => {
        if (props.getAllIsError) {
            logoutIfInvalidToken(token, dispatch, navigate);
        }
    });

    /**
     * Toggles the add dialog.
     */
    function toggleAddDialog() {
        if (props.openAddDialog) props.getAllRefetch();
        props.setOpenAddDialog(!props.openAddDialog);
    }

    /**
     * Gets the props for the dashboard layout. Only sets add button if the user has the required role.
     */
    function getLayoutProps() {
        let layoutProps: { topBarButtons?: ReactElement, setSearchValue?: Function } = {};

        layoutProps.setSearchValue = props.setSearchValue;
        if (me && props.addRole && userHasAccess(me.roles, props.addRole.valueOf())) {
            layoutProps.topBarButtons = (
                <TopBarButton onClick={toggleAddDialog}>
                    Nieuw
                </TopBarButton>
            );
        }

        return layoutProps;
    }

    return (
        <DashboardLayout {...getLayoutProps()}>
            <Grid container item id={"content"} xs>
                <PageHeadingGridItem>
                    <Typography variant={"h1"}> {props.pageHeading}</Typography>
                </PageHeadingGridItem>
                <PageContentGridItem>
                    <Grid container item spacing={"17px"} xs={12}>
                        {props.children}
                    </Grid>
                </PageContentGridItem>
                <Dialog
                    data-testid={"addDialog"}
                    className={props.visibleOverflows ? "visible-overflows" : ""}
                    open={props.openAddDialog}
                >
                    <DialogContentGridContainer>
                        <Grid container item xs={12}>
                            <Typography variant={"h1"}> {props.addDialogTitle}</Typography>
                            <IconButton data-testid={"closeAddDialogButton"} className={"close-dialog-button"} onClick={toggleAddDialog}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid container item xs={12}>
                            {props.addDialogForm}
                        </Grid>
                    </DialogContentGridContainer>
                </Dialog>
                <FormSnackbar
                    open={props.openConfirmationSnackbar}
                    setOpen={props.setOpenConfirmationSnackbar}
                    message={props.confirmationMessage}
                    severity={"success"}
                />
                {
                    (props.openValidationSnackbar !== undefined && props.setOpenValidationSnackbar && props.validationMessage) &&
                    <FormSnackbar
                        open={props.openValidationSnackbar}
                        setOpen={props.setOpenValidationSnackbar}
                        message={props.validationMessage}
                        severity={"error"}
                    />
                }
            </Grid>
        </DashboardLayout>
    );
}
