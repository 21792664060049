import { api } from "@store/api/api";
import { PaginationOptions } from "@app-types/PaginationOptions";

export const users = api.injectEndpoints({
    endpoints: (build) => ({
        /**
         * User authentication endpoint.
         */
        auth: build.mutation({
            query: (credentials) => ({
                url: "/auth",
                method: "POST",
                body: credentials
            })
        }),

        /**
         * Get own user entity.
         */
        getMe: build.query({
            query: () => ({
                url: "/api/users/me",
                method: "GET"
            }),
            providesTags: ["User"]
        }),

        /**
         * Get user entity by id.
         */
        getUser: build.query({
            query: (id) => ({
                url: `/api/users/${id}`,
                method: "GET"
            }),
            providesTags: ["User"]
        }),

        /**
         * Add user endpoint.
         */
        addUser: build.mutation({
            query: (body) => ({
                url: "/api/users",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["User"]
        }),

        /**
         * Update user endpoint.
         */
        updateUser: build.mutation({
            query: ({ id, body }) => ({
                url: `/api/users/${id}`,
                method: "PUT",
                body: body
            }),
            invalidatesTags: ["User"]
        }),

        /**
         * Get user collection endpoint.
         */
        getAllUsers: build.query<any, PaginationOptions>({
            query: (paginationOptions) => ({
                url: "/api/users",
                method: "GET",
                params: {
                    itemsPerPage: paginationOptions.itemsPerPage,
                    page: paginationOptions.page,
                    ...(paginationOptions.search && { search: paginationOptions.search })
                },
                refetchOnMountOrArgChange: true
            }),
            providesTags: ["User"]
        }),

        /**
         * Delete user endpoint.
         */
        deleteUser: build.mutation({
            query: (id) => ({
                url: `/api/users/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["User"]
        })
    })
});

export const {
    useAuthMutation,
    useLazyGetMeQuery,
    useAddUserMutation,
    useUpdateUserMutation,
    useGetAllUsersQuery,
    useDeleteUserMutation,
    useGetUserQuery
} = users;
