import {Button, Grid, TextField} from "@mui/material";
import React, {useState} from "react";
import {useFormik} from "formik";
import {login, setMe} from "@store/slices/auth";
import {getLoginErrorMessage} from "@utils/helpers/errorMessage.helpers";
import {useAuthMutation, useLazyGetMeQuery} from "@store/api/users";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import * as yup from "yup";
import {FormSnackbar} from "@components/snackbars";

/**
 * Login form component that is displayed in the login page.
 * @constructor
 */
export default function LoginForm() {
    const [auth] = useAuthMutation();
    const [trigger] = useLazyGetMeQuery();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    /**
     * Validation schema of the form.
     */
    const validationSchema = yup.object({
        email: yup.string()
            .email('Vul een valide emailadres in')
            .required('Email is verplicht'),
        password: yup.string()
            .min(8, 'Wachtwoord moet minimaal 8 karakters bevatten')
            .required('Wachtwoord is verplicht'),
    });

    /**
     * Initialization of the formik form.
     */
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const authResponse = await auth({
                    email: values.email,
                    password: values.password
                }).unwrap();
                dispatch(login(authResponse.token));

                const getMeResponse = await trigger({}, false).unwrap();
                dispatch(setMe(getMeResponse));

                navigate('/', {replace: true});
            } catch (error: any) {
                if (!error.data.message) console.log(error);
                setErrorMessage(getLoginErrorMessage(error.data.message));
                setOpenSnackbar(true);
            }
        }
    });

    return (
        <Grid item xs={12}>
            <form data-testid={"form"} onSubmit={formik.handleSubmit} noValidate>
                <TextField
                    id={"email"}
                    name={"email"}
                    label={"E-mailadres"}
                    type={"email"}
                    margin={"dense"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    inputProps={{
                        "data-testid": "email"
                    }}
                />
                <TextField
                    id={"password"}
                    name={"password"}
                    label={"Wachtwoord"}
                    type={"password"}
                    margin={"dense"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    inputProps={{
                        "data-testid": "password"
                    }}
                />
                <Button data-testid={"submit"} type={"submit"}>Inloggen</Button>
                <FormSnackbar
                    open={openSnackbar}
                    setOpen={setOpenSnackbar}
                    message={errorMessage}
                    severity={"error"}
                />
            </form>
        </Grid>
    );
}
