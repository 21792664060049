import {AnyObjectSchema} from "yup";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {useFormik} from "formik";
import {logout} from "@store/slices/auth";
import {getItemErrorMessage} from "@utils/helpers/errorMessage.helpers";
import {ItemRequestBody} from "@app-types/request-bodies/ItemRequestBody";
import {Button, TextField} from "@mui/material";
import {FormSnackbar} from "@components/snackbars";
import {useAddItemToCategoryMutation} from "@store/api/checklistCategory";

/**
 * @param closeSelf The function that closes the dialog.
 * @param itemValue Default value for the value input field. (optional)
 * @param expectedResult Default value for the expected result input field. (optional)
 * @param action The mutation action that should be executed on submit.
 * @param buttonText The text that the submit button should have.
 * @param projectTypeId The id of the project type that needs to be updated (optional)
 * @param showConfirmation Function that shows the confirmation snackbar with the correct message.
 */
interface Props {
    closeSelf: Function,
    itemValue?: string,
    expectedResult?: string,
    category?: any,
    action: Function,
    buttonText: string,
    itemId?: any,
    showConfirmation: Function,
    setOpenConfirmationSnackbar: Function
    validationSchema: AnyObjectSchema
}

export default function ItemForm(props: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [addItemToCategory] = useAddItemToCategoryMutation();

    /**
     * Initialization of the formik form.
     */
    const formik = useFormik({
        initialValues: {
            value: props.itemValue ?? "",
            expectedResult: props.expectedResult ?? "",
        },
        validationSchema: props.validationSchema,
        onSubmit: async (values: ItemRequestBody) => {
            try {
                props.setOpenConfirmationSnackbar(false);

                let body = {...values};

                if (props.itemId !== undefined) {
                    await props.action({id: props.itemId, body: body}).unwrap();
                } else {
                    const response = await props.action(body).unwrap();

                    if (props.category) await addItemToCategory({
                        categoryId: props.category.id,
                        itemId: response.id
                    }).unwrap();
                }

                props.showConfirmation();
                props.closeSelf();
            } catch (error: any) {
                if (error.data.message === "Expired JWT Token") {
                    dispatch(logout());
                    navigate("/login", {replace: true});
                }

                setErrorMessage(getItemErrorMessage(error));
                setOpenSnackbar(true);
            }
        }
    });

    return (
        <form data-testid={"form"} onSubmit={formik.handleSubmit} noValidate>
            <TextField
                id={"itemValue"}
                name={"value"}
                label={"Omschrijving"}
                type={"text"}
                margin={"dense"}
                value={formik.values.value}
                onChange={formik.handleChange}
                error={formik.touched.value && Boolean(formik.errors.value)}
                helperText={formik.touched.value && formik.errors.value}
                inputProps={{
                    "data-testid": "itemValue"
                }}
            />
            <TextField
                multiline
                id={"expectedResult"}
                className={"multiline-textfield"}
                name={"expectedResult"}
                label={"Verwachtte resultaat (optioneel)"}
                type={"text"}
                margin={"dense"}
                value={formik.values.expectedResult}
                onChange={formik.handleChange}
                error={formik.touched.expectedResult && Boolean(formik.errors.expectedResult)}
                helperText={formik.touched.expectedResult && formik.errors.expectedResult}
                inputProps={{
                    "data-testid": "expectedResult"
                }}
            />
            <Button data-testid={"submit"} type={"submit"}>{props.buttonText}</Button>
            <FormSnackbar
                open={openSnackbar}
                setOpen={setOpenSnackbar}
                message={errorMessage}
                severity={"error"}
            />
        </form>
    );
}
