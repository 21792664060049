import * as yup from "yup";

export const addProjectValidationSchema = yup.object({
    name: yup.string()
        .max(100, "Naam mag maximaal 100 karakters bevatten")
        .required("Naam is verplicht"),
    type: yup.object().shape({
        label: yup.string(),
        id: yup.number()
    }).nullable().required("Projectsoort is verplicht")
});
