import CrudPageLayout from '@components/layouts/CrudPageLayout/CrudPageLayout';
import React, { useState } from 'react';
import { addChecklistValidationSchema } from '@config/constants/validation-schemas/checklist/addChecklistValidationSchema';
import { ChecklistForm } from '@components/forms';
import { ChecklistResponseBody } from '@app-types/response-bodies/ChecklistResponseBody';
import { CrudTable } from '@components/tables';
import { Roles } from '@config/constants/security';
import { TemplatesRequestBody } from '@app-types/request-bodies/TemplatesRequestBody';
import {
    useAddChecklistMutation,
    useDeleteChecklistMutation,
    useGetAllChecklistsQuery,
    useUpdateChecklistMutation
} from '@store/api/checklists';
import { useNavigate } from 'react-router-dom';

export default function TemplatesPage() {
    const navigate = useNavigate();

    const [openAddTemplateDialog, setOpenAddTemplateDialog] = useState(false);
    const [updateDialog, setUpdateDialog] = useState<{ open: boolean, entity: TemplatesRequestBody | undefined }>({
        open: false,
        entity: undefined
    });

    const [addChecklist] = useAddChecklistMutation();
    const [deleteChecklist] = useDeleteChecklistMutation();
    const [updateChecklist] = useUpdateChecklistMutation();

    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);

    const [tablePage, setTablePage] = useState(1);
    const [rowsPerTablePage, setRowsPerTablePage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const { data, isSuccess, isFetching, isError, refetch } = useGetAllChecklistsQuery({
        itemsPerPage: rowsPerTablePage,
        page: tablePage,
        ...(searchValue !== "" && { search: searchValue })
    });

    /**
     * Shows the confirmation snackbar.
     * @param message The message the confirmation snackbar should display.
     */
    function showConfirmationSnackbar(message: string) {
        setConfirmationMessage(message);
        setOpenConfirmationSnackbar(true);
    }

    React.useEffect(() => setTablePage(1), [searchValue]);

    return (
        <CrudPageLayout
            addDialogTitle={"Template toevoegen"}
            addDialogForm={
                <ChecklistForm
                    closeSelf={() => setOpenAddTemplateDialog(!openAddTemplateDialog)}
                    action={addChecklist}
                    validationSchema={addChecklistValidationSchema}
                    buttonText={"Toevoegen"}
                    showConfirmation={() => showConfirmationSnackbar("De template is succesvol toegevoegd.")}
                    setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                />
            }
            openAddDialog={openAddTemplateDialog}
            setOpenAddDialog={setOpenAddTemplateDialog}
            openConfirmationSnackbar={openConfirmationSnackbar}
            setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
            confirmationMessage={confirmationMessage}
            getAllIsError={isError}
            getAllRefetch={refetch}
            pageHeading={"Templates"}
            addRole={Roles.SuperAdmin}
            setSearchValue={setSearchValue}
        >
            <CrudTable
                data={data}
                isSuccess={isSuccess}
                isFetching={isFetching}
                refetch={refetch}
                page={tablePage}
                rowsPerPage={rowsPerTablePage}
                setPage={setTablePage}
                setRowsPerPage={setRowsPerTablePage}
                setConfirmationMessage={setConfirmationMessage}
                setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                deleteAction={deleteChecklist}
                deleteConfirmationMessage={"De template is succesvol verwijderd."}
                deleteDialogText={"Weet je zeker dat je deze template wilt verwijderen?"}
                columns={
                    [
                        { id: "name", label: "Template" },
                        { id: "itemCount", label: "Aantal vragen" },
                        { id: "options", label: "Opties", classes: "options-cell" }
                    ]
                }
                updateDialogTitle={"Template wijzigen"}
                updateForm={
                    <ChecklistForm
                        closeSelf={() => setUpdateDialog({ open: !updateDialog.open, entity: updateDialog.entity })}
                        action={updateChecklist}
                        validationSchema={addChecklistValidationSchema}
                        buttonText={"Wijzigen"}
                        showConfirmation={() => showConfirmationSnackbar("De template is succesvol gewijzigd.")}
                        setOpenConfirmationSnackbar={setOpenConfirmationSnackbar}
                        checklistId={updateDialog.entity?.id}
                        checklistName={updateDialog.entity?.name}
                    />
                }
                updateDialog={updateDialog}
                setUpdateDialog={setUpdateDialog}
                onRowClick={(key: number) => navigate(`/templates/${key}`)}
            />
        </CrudPageLayout>
    );
}
