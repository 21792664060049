import {api} from "@store/api/api";
import {PaginationOptions} from "@app-types/PaginationOptions";

export const projectTypes = api.injectEndpoints({
    endpoints: (build) => ({
        /**
         * Add project type endpoint.
         */
        addProjectType: build.mutation({
            query: (body) => ({
                url: "/api/project_types",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["ProjectType"]
        }),

        /**
         * Get project types collection endpoint.
         */
        getAllProjectTypes: build.query<any, PaginationOptions>({
            query: (paginationOptions) => ({
                url: "/api/project_types",
                method: "GET",
                params: {
                    itemsPerPage: paginationOptions.itemsPerPage,
                    page: paginationOptions.page,
                    ...(paginationOptions.search && { search: paginationOptions.search })
                },
                refetchOnMountOrArgChange: true
            }),
            providesTags: ["ProjectType"]
        }),

        /**
         * Get a project type by its uri.
         */
        getProjectType: build.query({
            query: (typeUri) => ({
                url: typeUri,
                method: "GET"
            }),
            providesTags: ["ProjectType"]
        }),

        /**
         * Delete project type endpoint.
         */
        deleteProjectType: build.mutation({
            query: (id) => ({
                url: `/api/project_types/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["ProjectType"]
        }),

        /**
         * Update project type endpoint.
         */
        updateProjectType: build.mutation({
            query: ({id, body}) => ({
                url: `/api/project_types/${id}`,
                method: "PUT",
                body: body
            }),
            invalidatesTags: ["ProjectType"]
        }),

        getProjectTypeChecklists: build.query({
            query: (typeUri) => ({
                url: `${typeUri}/checklists`,
                method: "GET"
            }),
            providesTags: ["ProjectType", "Checklist"]
        }),
    })
});

export const {
    useAddProjectTypeMutation,
    useGetAllProjectTypesQuery,
    useDeleteProjectTypeMutation,
    useGetProjectTypeQuery,
    useUpdateProjectTypeMutation,
    useGetProjectTypeChecklistsQuery
} = projectTypes;
