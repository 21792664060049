import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';

/**
 * Welcome message component that is displayed in the login page.
 * @constructor
 */
export default function WelcomeMessage() {
    const [message, setMessage] = useState(getMessage);

    /**
     * Determines the welcome message based on the hour of the current time of the timezone the user is in.
     */
    function getMessage() {
        let hours = new Date().getHours();
        let message;

        if (hours >= 6 && hours < 12) {
            message = "Goedemorgen!";
        } else if (hours >= 12 && hours < 18) {
            message = "Goedemiddag!";
        } else if (hours >= 18 && hours <= 23) {
            message = "Goedenavond!";
        } else if (hours >= 0 && hours < 6) {
            message = "Goedenacht!";
        } else {
            message = "Welkom!";
        }

        return message;
    }

    /**
     * Checks every hour if the message should change beginning at the start of the next hour.
     */
    let toHour = new Date().getTime() % 3600000;
    setTimeout(() => {
        setInterval(() => { setMessage(getMessage) }, 3600000);
        setMessage(getMessage);
    }, 3600000 - toHour);

    return (
        <Grid item xs={12}>
            <Typography variant={"h1"}> {message}</Typography>
            <Typography variant={"h1"}> Wie wil er inloggen?</Typography>
        </Grid>
    )
}
