import {Skeleton, TableCell, TableRow} from "@mui/material";
import React from "react";
import {useGetCategoryItemQuery, useGetChecklistCategoryQuery} from "@store/api/checklistCategory";
import {skipToken} from "@reduxjs/toolkit/query";
import {translateAnswer} from "@utils/helpers/result.helpers";
import {useGetItemQuery} from "@store/api/items";

interface Props {
    result: any,
}

export default function ResultTableRow({result}: Props) {
    const {data: categoryItem, isSuccess: categoryItemIsSuccess} = useGetCategoryItemQuery(result.categoryItem);
    const {data: item, isSuccess: itemIsSuccess} = useGetItemQuery(categoryItem ? categoryItem.item.split("/").pop() : skipToken);
    const {data: category, isSuccess: categoryIsSuccess} = useGetChecklistCategoryQuery(categoryItem ? categoryItem.category : skipToken);

    return (
        <>
            {
                categoryItemIsSuccess && categoryIsSuccess && itemIsSuccess ?
                    <TableRow className={"valign-top"}>
                        <TableCell key={"category"}>{category.name}</TableCell>
                        <TableCell key={"item"}>{item.value}</TableCell>
                        <TableCell key={"expectedResult"}>{item.expectedResult}</TableCell>
                        <TableCell key={"actualResult"}>{translateAnswer(result.answer)}</TableCell>
                        <TableCell key={"note"}>{result.note}</TableCell>
                    </TableRow> :
                    <TableRow>
                        <TableCell key={"category"}><Skeleton/></TableCell>
                        <TableCell key={"item"}><Skeleton/></TableCell>
                        <TableCell key={"expectedResult"}><Skeleton/></TableCell>
                        <TableCell key={"actualResult"}><Skeleton/></TableCell>
                        <TableCell key={"note"}><Skeleton/></TableCell>
                    </TableRow>

            }
        </>
    );
}
