import "./ItemsTable.styles.scss";
import React from "react";
import {
    Grid,
    Table,
    TableBody,
    TableContainer,
} from "@mui/material";
import {useAddItemToCategoryMutation, useGetChecklistCategoryItemsQuery} from "@store/api/checklistCategory";
import DraggableTableRow from "@components/tables/rows/DraggableTableRow";
import {useDrop} from "react-dnd";
import {ItemTypes} from "@config/constants/ItemTypes";
import {getChecklistErrorMessage} from "@utils/helpers/errorMessage.helpers";
import {logout} from "@store/slices/auth";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {skipToken} from "@reduxjs/toolkit/query";

interface Props {
    category?: any;
    itemEntities?: any[];
    showValidationSnackbar: Function;
}

/**
 * Table that displays all items or all items of a checklist category.
 * @param category The category to display the items of. (Optional)
 * @param itemEntities The items to display. (Optional)
 * @param showValidationSnackbar Function to show a validation snackbar.
 * @constructor
 */
export default function ItemsTable({category, itemEntities, showValidationSnackbar}: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [addItemToCategory] = useAddItemToCategoryMutation();
    const {data, isSuccess} = useGetChecklistCategoryItemsQuery(category ? category.id : skipToken);
    const [{canDrop, getItem}, drop] = useDrop(() => ({
        accept: ItemTypes.ITEM,
        drop: async (item: {id: number}) => handleDrop(item),
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            getItem: monitor.getItem()
        })
    }));

    /**
     * Handles the drop of an item on a category.
     * @param item The item that was dropped.
     */
    async function handleDrop(item: {id: number}) {
        try {
            await addItemToCategory({
                categoryId: category.id,
                itemId: item.id
            }).unwrap();
        } catch (error: any) {
            if (error.data.message === "Expired JWT Token") {
                dispatch(logout());
                navigate("/login", {replace: true});
            }

            showValidationSnackbar(getChecklistErrorMessage(error));
        }

    }

    /**
     * Checks if the item is already in the category.
     */
    function itemIsInCategory() {
        if (category && canDrop && data) {
            if (data["hydra:member"].find((item: any) => item.item.id === getItem.id)) {
                return true;
            }
        }

        return false;
    }

    return (
        <Grid container item xs={12}>
            <TableContainer>
                <Table className={"items-table"}>
                    <TableBody>
                        {
                            itemEntities && !category &&
                            itemEntities.map((item: any) => (
                                <DraggableTableRow key={item.id} item={item} type={ItemTypes.ITEM}/>
                            ))
                        }
                        {
                            category && isSuccess && !itemEntities &&
                            data["hydra:member"].map((item: any) => (
                                <DraggableTableRow key={item.id} item={item} type={ItemTypes.CATEGORY_ITEM}/>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                canDrop && category &&
                <Grid container item
                      className={itemIsInCategory() ? "drop-zone-error" : "drop-zone"}
                      ref={itemIsInCategory() ? undefined : drop}
                      xs
                >
                    <span>
                        {
                            itemIsInCategory() ?
                            "Die vraag bestaat al in deze categorie" :
                            "Sleep je vraag hierheen om toe te voegen"
                        }
                    </span>
                </Grid>
            }
        </Grid>
    );
}

