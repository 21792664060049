import React from "react";
import HomeLayout from "@components/layouts/HomeLayout/HomeLayout";
import WelcomeMessage from "@views/LoginPage/WelcomeMessage/WelcomeMessage";
import LoginForm from "@views/LoginPage/LoginForm/LoginForm";
import {Grid, Link} from "@mui/material";

/**
 * Login page component using the home layout.
 * @constructor
 */
export default function LoginPage() {
    return (
        <HomeLayout>
            <Grid container item alignItems={"center"} spacing={1} xs={9} lg={6}>
                <WelcomeMessage/>
                <LoginForm/>
                <Grid item textAlign={"center"} xs={12} mt={4} mb={6}>
                    <Link data-testid={"reset-password"} href={"/login"}>Wachtwoord vergeten?</Link>
                </Grid>
            </Grid>
        </HomeLayout>
    );
}
