import NavButton from '@components/layouts/DashboardLayout/NavBar/NavButton/NavButton';
import React, { ReactElement, useState } from 'react';
import userHasAccess from '@utils/helpers/security.helpers';
import {
    Avatar,
    Button,
    colors,
    Grid,
    Link,
    List,
    Slide,
    styled
} from '@mui/material';
import { logout } from '@store/slices/auth';
import { ReactComponent as BlockQuoteIcon } from '@assets/images/icons/block-quote-regular.svg';
import { ReactComponent as BlockQuoteIcon2 } from '@assets/images/icons/block-quote-regular-2.svg';
import { ReactComponent as CheckIcon } from '@assets/images/icons/check-regular.svg';
import { ReactComponent as GearIcon } from '@assets/images/icons/gear-light.svg';
import { Roles } from '@config/constants/security';
import { useAppSelector } from '@store/hooks/hooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './NavBar.styles.scss';
import logo from "@assets/images/logo/Group 8142.svg";

const NavGrid = styled(Grid)(({ theme }) => ({
    justifyContent: "center",
    backgroundColor: colors.common.white,
    fontWeight: theme.fontWeights.semiBold,

    "div:first-of-type": {
        marginTop: 0,
    },

    [theme.breakpoints.down("lg")]: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

const NameGrid = styled(Grid)(({ theme }) => ({
    justifyContent: "center",
    fontSize: "13px",
    fontWeight: theme.fontWeights.semiBold,
    marginTop: "16px",
    textAlign: "center",
    wordWrap: "break-word",

    [theme.breakpoints.down("lg")]: {
        fontSize: "16px",
    },
}));

export type NavItem = {
    id: string;
    icon?: ReactElement;
    text: string;
    path?: string;
    role?: string;
    children?: Array<NavItem>;
}

interface Props {
    open: boolean,
    setOpen: Function
}

/**
 * Navigation bar component that displays on the left side of the dashboard layout.
 * @constructor
 */
export default function NavBar({ open, setOpen }: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const navItems: NavItem[] = [
        {
            id: "projects",
            icon: <BlockQuoteIcon />,
            text: "Projecten",
            path: "/projects"
        },
        {
            id: "checks",
            icon: <CheckIcon />,
            text: "Checks",
            path: "/checks"
        },
        {
            id: "settings",
            icon: <GearIcon />,
            text: "Instellingen",
            children: [
                {
                    id: "users",
                    text: "Gebruikers",
                    path: "/users",
                    role: Roles.SuperAdmin.toString()
                },
                {
                    id: "templates",
                    text: "Templates",
                    path: "/templates",
                    role: Roles.SuperAdmin.toString()
                },
                {
                    id: "items",
                    text: "Vragen",
                    path: "/items",
                    role: Roles.SuperAdmin.toString()
                },
                {
                    id: "projectTypes",
                    text: "Projectsoorten",
                    path: "/project-types",
                    role: Roles.SuperAdmin.toString()
                }
            ]
        },
    ];
    const [selectedItems, setSelectedItems] = useState<Array<NavItem>>(getSelectedItemsByLocation);
    const me = useAppSelector((state) => state.auth.me);

    /**
     * Determines the navigation items that should be selected based on the URL of the page.
     */
    function getSelectedItemsByLocation(): Array<NavItem> {
        let items: Array<NavItem> = [];

        if (window.location.pathname === "/") items = [navItems[0]];

        navItems.forEach(item => {
            if (item.path && window.location.pathname.includes(item.path)) items = [item];

            item.children?.forEach(child => {
                if (child.path && window.location.pathname.includes(child.path)) items = [child, item];
            });
        });

        return items;
    }

    /**
     * Selects the navigation items with the ids that are provided.
     * @param ids The ids of the navigation items that should be selected.
     */
    function setSelectedItemsById(ids: string[]) {
        let items: Array<NavItem> = [];

        navItems.forEach(item => {
            ids.forEach(id => {
                if (item.id === id) items.push(item);

                item.children?.forEach(child => {
                    if (child.id === id) items.push(child);
                });
            });
        });

        setSelectedItems(items);
    }

    /**
     * Logs out the user and redirects to the login page.
     */
    function logoutUser() {
        try {
            dispatch(logout());
            navigate('/login', { replace: true });
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Determines whether the parent item should be displayed based on the role of the children.
     * @param item The parent item.
     */
    function shouldDisplayParentItem(item: NavItem) {
        if (item.children) {
            return item.children.some(child => {
                if (child.role && me?.roles) {
                    return userHasAccess(me.roles, child.role);
                }
                return true;
            });
        }

        return true;
    }

    return (
        <Slide direction={"down"} in={open} appear={false} mountOnEnter unmountOnExit>
            <Grid container item data-testid={"navbar"} id={"navbar"} justifyContent={"center"} xs={12}>
                <Grid container item direction={"column"} xs={11}>
                    <Grid container item id={"logo-container"}>
                        <img id={"navbar-logo"} src={logo} alt={"Check'r logo"} />
                        <Button id={"navbar-button"} fullWidth={false} variant={"text"} onClick={() => setOpen(!open)}>
                            <BlockQuoteIcon2 id={"navbar-button-icon"} />
                            Sluiten
                        </Button>
                    </Grid>
                    <NavGrid item xs>
                        <List className={"nav-list"}>
                            {
                                navItems.map((item: NavItem) =>
                                    shouldDisplayParentItem(item) &&
                                    <NavButton
                                        key={item.id}
                                        icon={item.icon}
                                        text={item.text}
                                        navPath={item.path}
                                        index={item.id}
                                        role={item.role}
                                        selectedItems={selectedItems}
                                        setSelectedItems={setSelectedItemsById}
                                    >
                                        {
                                            item.children && item.children.map((subItem: NavItem) =>
                                                <NavButton
                                                    key={subItem.id}
                                                    text={subItem.text}
                                                    index={subItem.id}
                                                    navPath={subItem.path}
                                                    role={subItem.role}
                                                    parentIndex={item.id}
                                                    selectedItems={selectedItems}
                                                    setSelectedItems={setSelectedItemsById}
                                                />
                                            )
                                        }
                                    </NavButton>
                                )
                            }
                        </List>
                    </NavGrid>
                    <Grid container item id={"account-container"}>
                        <Grid container item id="avatar-container" lg={12}>
                            <Avatar id={"avatar"} alt={"Profile picture"}>{me ? me.name.slice(0, 1) : ""}</Avatar>
                        </Grid>
                        <NameGrid container item lg={12}>
                            <span id={"name"}>{me ? me.name : "Naam"}</span>
                        </NameGrid>
                        <Grid container item id={"logout-container"} lg={12}>
                            <Link id={"logout-link"} onClick={logoutUser}>Afmelden</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    );
}
