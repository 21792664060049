import "./ScoreTag.styles.scss";
import React from "react";
import {Grid} from "@mui/material";

interface Props {
    score: number
}

/**
 * Component that shows a tag with score percentage.
 * @param score The score that should be displayed.
 * @constructor
 */
export default function ScoreTag({ score }: Props) {
    const variant = calcVariant();

    function calcVariant(){
        if (score < 50) {
            return "error"
        } else if (score < 75) {
            return "warning"
        } else {
            return "success"
        }
    }

    return (
        <Grid container item className={`score score-${variant}`}>
            {`${score}%`}
        </Grid>
    );
}
