import { api } from "@store/api/api";

export const projects = api.injectEndpoints({
    endpoints: (build) => ({
        /**
         * Get project endpoint
         */
        getProject: build.query({
            query: (id) => ({
                url: `/api/projects/${id}`,
                method: "GET"
            }),
            providesTags: ["Project"]
        }),

        /**
         * Get project collection endpoint
         */
        getAllProjects: build.query<any, { search?: string }>({
            query: (params) => ({
                url: "/api/projects",
                method: "GET",
                params: {
                    ...(params.search && { search: params.search })
                },
                refetchOnMountOrArgChange: true
            }),
            providesTags: ["Project"]
        }),

        /**
         * Add project endpoint.
         */
        addProject: build.mutation({
            query: (body) => ({
                url: "/api/projects",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["Project"]
        }),

        /**
         * Delete project endpoint.
         */
        deleteProject: build.mutation({
            query: (id) => ({
                url: `/api/projects/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Project"]
        }),

        /**
         * Update project endpoint.
         */
        updateProject: build.mutation({
            query: ({ id, body }) => ({
                url: `/api/projects/${id}`,
                method: "PUT",
                body: body
            }),
            invalidatesTags: ["Project"]
        }),
    })
});

export const {
    useGetAllProjectsQuery,
    useAddProjectMutation,
    useDeleteProjectMutation,
    useUpdateProjectMutation,
    useGetProjectQuery
} = projects;
