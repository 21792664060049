import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useEffect, useState } from 'react';
import userHasAccess from '@utils/helpers/security.helpers';
import { convertPhpDateTimeToLocalDateString } from '@utils/helpers/dateTime.helpers';
import { getProjectStatusTagType, ProjectStatus } from '@config/constants/projects/projectStatus';
import { ProjectResponseBody } from '@app-types/response-bodies/ProjectResponseBody';
import { ReactComponent as CalendarLight } from '@assets/images/icons/calendar-light.svg';
import { Roles } from '@config/constants/security';
import { StatusTag } from '@components/tags';
import { useAppSelector } from '@store/hooks/hooks';
import { useGetProjectTypeQuery } from '@store/api/projectTypes';
import './ProjectCard.styles.scss';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    FormLabel,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover,
    Skeleton,
    styled,
    Tooltip,
} from '@mui/material';

const ErrorListItemText = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.error.main
}));

interface Props {
    project: ProjectResponseBody,
    successRate: number,
    status?: ProjectStatus,
    deleteProject: Function,
    updateProject: Function,
}

/**
 * Card component for displaying projects on the Project Page
 * @param project The project whose information should be displayed.
 * @param successRate The average success rate of the past 30 days.
 * @param status The status of the project.
 * @param deleteProject The function that deletes a project.
 * @param updateProject The function that updates a project.
 * @constructor
 */
export default function ProjectCard({ project, successRate, status, deleteProject, updateProject }: Props) {
    const progressColor = determineProgressColor();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [openPopover, setOpenPopover] = useState(false);

    const me = useAppSelector(state => state.auth.me);

    const { data, isSuccess } = useGetProjectTypeQuery(project.type.slice(1), {});

    /**
     * Determines the color of the average success rate progress bar.
     */
    function determineProgressColor(): "success" | "warning" | "error" {
        let color: "success" | "warning" | "error" = "success";
        if (successRate <= 75) color = "warning";
        if (successRate <= 50) color = "error";
        return color;
    }

    /**
     * Opens the popover.
     * @param event
     */
    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
        setOpenPopover(true);
    }

    /**
     * Toggles the popover.
     */
    function togglePopover() {
        setOpenPopover(!openPopover);
    }

    return (
        <Grid container item className={"card-container"} xs={12} sm={6} lg={3} xl={12 / 5}>
            <Card data-testid={"project-card"} className={"card"}>
                <CardHeader
                    title={isSuccess ? project.name : <Skeleton variant={"text"} />}
                    subheader={isSuccess ? data.name : <Skeleton variant={"text"} />}
                    action={
                        (me && userHasAccess(me.roles, Roles.SuperAdmin)) &&
                        <IconButton data-testid={"projectMenu" + project.id} size={"small"} onClick={handleClick} disableRipple>
                            <MoreVertIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    {
                        isSuccess ?
                            <>
                                <Grid container item className={"form-label-container"} xs={12}>
                                    <FormLabel>{successRate}%</FormLabel>
                                </Grid>
                                <Tooltip
                                    title={"Gem. slagingsscore afgelopen 30 dagen"}
                                    placement={"top"}
                                    arrow
                                >
                                    <LinearProgress
                                        className={"project-progress"}
                                        variant="determinate"
                                        value={successRate}
                                        color={progressColor}
                                    />
                                </Tooltip>
                            </> : <Skeleton variant={"rectangular"} />
                    }
                </CardContent>
                <CardActions>
                    {
                        isSuccess ?
                            <Grid container item alignItems={"center"} justifyContent={"space-between"}>
                                <Grid item>
                                    {
                                        project.lastCheckDate ?
                                            <Tooltip title={"Laatst uitgevoerde check"} placement={"top"} arrow>
                                                <Grid container item alignItems={"center"}>
                                                    <Grid item><CalendarLight /></Grid>
                                                    <Grid container item alignContent={"center"} xs><span>&nbsp;{convertPhpDateTimeToLocalDateString(project.lastCheckDate)}</span></Grid>
                                                </Grid>
                                            </Tooltip> : <Grid container item alignContent={"center"}><span>Nog geen checks</span></Grid>
                                    }
                                </Grid>
                                <Grid item>
                                    {
                                        status &&
                                        <StatusTag text={status} variant={getProjectStatusTagType(status)} />
                                    }
                                </Grid>
                            </Grid> : <Skeleton variant={"rectangular"} width={"100%"} />
                    }
                </CardActions>
            </Card>
            {
                (me && userHasAccess(me.roles, Roles.SuperAdmin)) &&
                <Popover
                    className={"card-popover"}
                    open={openPopover}
                    onClose={togglePopover}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 28,
                        horizontal: -135
                    }}
                >
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton data-testid={"updateButton" + project.id} onClick={() => {
                                updateProject();
                                togglePopover();
                            }}>
                                <ListItemText primary={"Wijzigen"} />
                            </ListItemButton>
                        </ListItem>
                        <Divider variant={"middle"} />
                        <ListItem disablePadding>
                            <ListItemButton data-testid={"deleteButton" + project.id} onClick={() => deleteProject()}>
                                <ErrorListItemText primary={"Verwijderen"} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Popover>
            }
        </Grid>
    );
}
