import {
    colors,
    createTheme,
    darken,
    lighten,
    PaletteColor
} from '@mui/material';

declare module "@mui/material/styles" {
    interface Palette {
        border: PaletteColor;
        backgroundColor: PaletteColor;
    }

    interface PaletteOptions {
        border?: PaletteColor;
        backgroundColor?: PaletteColor;
    }

    interface Theme {
        fontWeights: {
            semiBold: number;
        }
    }

    interface ThemeOptions {
        fontWeights?: {
            semiBold?: number;
        }
    }
}

export const semiBold = 600;

export const checkrTheme = createTheme({
    typography: {
        fontFamily: [
            "Inter",
            "sans-serif"
        ].join(','),
        h1: {
            margin: "10px 0 10px 0",
            fontSize: "24px",
            fontWeight: semiBold,
        },
        h2: {
            margin: "10px 0 10px 0",
            fontSize: "18px",
            fontWeight: semiBold,
        },
        h3: {
            margin: "10px 0 10px 0",
            fontSize: "15px",
            fontWeight: semiBold,
        },
    },
    fontWeights: {
        semiBold: 600,
    },
    palette: {
        primary: {
            main: '#03D07E',
            dark: darken("#03D07E", 0.1),
            contrastText: colors.common.white
        },
        secondary: {
            main: '#2345FF',
            contrastText: colors.common.white
        },
        success: {
            main: '#03D07E'
        },
        warning: {
            main: '#FF9300'
        },
        error: {
            main: '#E60047'
        },
        text: {
            primary: colors.common.black,
            secondary: "#53646E",
        },
        border: {
            light: lighten("#DAE3F1", 0.1),
            main: "#DAE3F1",
            dark: "#CDD8E5",
            contrastText: colors.common.black
        },
        backgroundColor: {
            light: colors.common.white,
            main: colors.common.white,
            dark: "#F8FAFB",
            contrastText: colors.common.black
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                fullWidth: true
            }
        },
        MuiLink: {
            defaultProps: {
                color: "#53646E"
            },
            styleOverrides: {
                root: {
                    textDecorationColor: "inherit",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: colors.common.white,
                    color: "#53646E",
                    borderRadius: "2px",
                    border: "1px solid #E5E5E5",
                    padding: "8px 13px 8px 13px"
                },
                arrow: {
                    color: colors.common.white,
                    "&:before": {
                        border: "1px solid #E5E5E5"
                    },
                }
            }
        },

        // Button components
        MuiButton: {
            defaultProps: {
                variant: "contained",
                color: "primary",
                fullWidth: true
            },
            styleOverrides: {
                root: {
                    borderRadius: "2px",
                    textTransform: "none",
                    fontWeight: semiBold,
                    padding: "10px 0 10px 0",
                    boxShadow: "none",
                    marginTop: "7px",
                },
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&.close-dialog-button": {
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                    },
                },
            },
        },

        // Form components
        MuiInputBase: {
            defaultProps: {
                color: "secondary"
            },
            styleOverrides: {
                root: (props) => ({
                    backgroundColor: colors.common.white,
                    borderRadius: "2px !important",
                    borderWidth: "1px !important",
                    borderColor: `${props.theme.palette.border.main} !important`,
                    color: props.theme.palette.text.secondary,

                    "&.Mui-error": {
                        "fieldset.MuiOutlinedInput-notchedOutline": {
                            borderColor: `${props.theme.palette.error.main} !important`,
                        },
                    },
                }),
                input: {
                    fontSize: "14px",
                },
                inputMultiline: {
                    fontSize: "14px",
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: (props) => ({
                    borderRadius: "2px !important",
                    borderWidth: "1px !important",
                    borderColor: `${props.theme.palette.border.main} !important`,
                }),
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: (props) => ({
                    fontSize: "15px",
                    color: `${props.theme.palette.text.primary} !important`,
                    fontWeight: semiBold,
                }),
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: (props) => ({
                    fontSize: "15px",
                    color: props.theme.palette.text.secondary,
                }),
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: (props) => ({
                    fontSize: "14px",
                    padding: 0,
                    fontWeight: "normal",
                    color: `${props.theme.palette.text.secondary} !important`,
                    transform: "translate(14px, 12px) scale(1)",
                    transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",

                    "&.Mui-focused": {
                        fontSize: "12px",
                        color: `${props.theme.palette.text.primary} !important`,
                        fontWeight: semiBold,
                        transform: "translate(14px, -9px) scale(1)",
                    },

                    "&.MuiFormLabel-filled": {
                        fontSize: "12px",
                        transform: "translate(14px, -9px) scale(1)",
                    },
                }),
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    "&.always-focused": {
                        "label.MuiInputLabel-root": {
                            fontWeight: "normal !important",
                        },
                        "input.MuiAutocomplete-input": {
                            paddingTop: "10px !important",
                        },
                    },
                    "&:not(.always-focused)": {
                        "input.MuiAutocomplete-input": {
                            padding: "0 !important",
                        },
                    },
                    "label.radio-label": {
                        "span": {
                            fontSize: "13px",
                        }
                    },
                    "&:not(.multiline-textfield)": {
                        "div.MuiInputBase-root, div.MuiInputBase-root.Mui-focused": {
                            "input.MuiInputBase-input": {
                                height: "44px",
                                padding: "0 14px",
                            },
                        }
                    },
                },
            },
        },
        MuiAutocomplete: {
            variants: [
                {
                    props: { multiple: false },
                    style: {
                        "div.MuiInputBase-root, div.MuiInputBase-root.Mui-focused": {
                            padding: "0 14px",
                        },
                    },
                }
            ],
            styleOverrides: {
                inputRoot: (props) => ({
                    "input.MuiInputBase-input": {
                        padding: "0 0 0 5px !important",

                        "&::placeholder": {
                            color: props.theme.palette.text.secondary,
                            opacity: 1,
                        },
                    }
                }),
                option: (props) => ({
                    "&[aria-selected=true]": {
                        backgroundColor: `${props.theme.palette.secondary.main} !important`,
                        color: colors.common.white,
                    },
                }),
                listbox: {
                    padding: 0,
                },
                popper: (props) => ({
                    border: `1px solid ${props.theme.palette.border.main}`
                }),
            },
        },

        // Table components
        MuiTable: {
            styleOverrides: {
                root: {
                    "&.read-only": {
                        "td.MuiTableCell-root": {
                            backgroundColor: "transparent",
                        },
                    },
                },
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&.clickable": {
                        cursor: "pointer",
                        position: "relative",
                        zIndex: 0,

                        "&:hover": {
                            boxShadow: "0px 3px 40px #00000014",
                            webkitBoxShadow: "0px 3px 40px #00000014",
                            zIndex: 1,
                        },
                    },
                    "&.valign-top": {
                        verticalAlign: "top !important",
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: (props) => ({
                    fontSize: "13px",
                    borderBottom: "1px solid",
                    borderRight: "1px solid",

                    "&:first-of-type": {
                        borderLeft: `1px solid ${props.theme.palette.border.main}`,
                    },

                    "&.valign-top": {
                        verticalAlign: "top !important",
                    },
                }),
                head: (props) => ({
                    padding: "11px 19px 11px 19px",
                    fontWeight: semiBold,
                    borderTop: "1px solid",
                    borderColor: props.theme.palette.border.main,
                    backgroundColor: props.theme.palette.backgroundColor.dark,
                }),
                body: (props) => ({
                    padding: "23px 19px 23px 19px",
                    borderColor: props.theme.palette.border.main,
                    backgroundColor: props.theme.palette.backgroundColor.main,
                    color: props.theme.palette.text.secondary,
                    backgroundClip: "padding-box",
                }),
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                menuItem: (props) => ({
                    "&.Mui-selected": {
                        backgroundColor: `${props.theme.palette.secondary.main} !important`,
                        color: props.theme.palette.backgroundColor.main,
                    },
                }),
            },
        },

        // Dialog components
        MuiDialog: {
            styleOverrides: {
                root: {
                    "&.visible-overflows": {
                        ".MuiDialog-container .MuiPaper-root": {
                            overflow: "visible",
                        }
                    },
                },
                paper: {
                    borderRadius: 0,
                    maxHeight: "80%",
                    overflowY: "auto",
                },
            },
        },
    }
});
