import NavBar from '@components/layouts/DashboardLayout/NavBar/NavBar';
import React, {
    ReactElement,
    ReactNode,
    useEffect,
    useState
} from 'react';
import SearchTextField from '@components/inputs/SearchTextField';
import TopBar from '@components/layouts/DashboardLayout/TopBar/TopBar';
import TopBarButtonLayout from '@components/layouts/DashboardLayout/TopBar/TopBarButtonLayout/TopBarButtonLayout';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as BlockQuoteIcon2 } from '@assets/images/icons/block-quote-regular-2.svg';
import { TopBarButton } from '@components/buttons';
import { useDebounce } from '@store/hooks/hooks';
import './DashboardLayout.styles.scss';
import logo from "@assets/images/logo/Group 8142.svg";

interface Props {
    children?: ReactNode
    topBarButtons?: ReactElement
    setSearchValue?: Function
}

/**
 * Component that defines the base layout of the private dashboard.
 * @param children The components that should be displayed inside the layout.
 * @param topBarButtons The buttons that should be displayed in the top bar.
 * @constructor
 */
export default function DashboardLayout({ children, topBarButtons, setSearchValue }: Props) {
    const theme = useTheme();
    const isSmallerThanLarge = useMediaQuery(theme.breakpoints.down("lg"));
    const [openNavbar, setOpenNavbar] = useState(!isSmallerThanLarge);

    const [searchFieldInput, setSearchFieldInput] = useState("");
    const debouncedSearchValue = useDebounce(searchFieldInput, 500);

    /**
     * Determines whether the content (everything next to the navbar) should display or hide based on
     * the current breakpoint.
     */
    const contentShouldDisplay = () => {
        if (isSmallerThanLarge) {
            return !openNavbar;
        } else {
            if (!openNavbar) setOpenNavbar(true);
            return true;
        }
    }

    const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!setSearchValue) return;
        setSearchFieldInput(event.target.value)
    };

    useEffect(() => {
        if (!setSearchValue) return;
        setSearchValue(debouncedSearchValue);
    }, [debouncedSearchValue]);

    return (
        <Grid container id={"dashboard-container"}>
            <NavBar open={openNavbar} setOpen={setOpenNavbar} />
            {
                contentShouldDisplay() &&
                <Grid container item id={"content-container"} direction={"column"} xs>
                    {
                        (isSmallerThanLarge && !openNavbar) &&
                        <TopBar>
                            <Grid container item xs>
                                <img id={"menubar-logo"} src={logo} alt={"Check'r logo"} />
                            </Grid>
                            <TopBarButtonLayout>
                                <TopBarButton test-id={"navbarButton"} className={"navbar-button"} onClick={() => setOpenNavbar(!openNavbar)}>
                                    <BlockQuoteIcon2 id={"navbar-button-icon"} />
                                    Menu
                                </TopBarButton>
                            </TopBarButtonLayout>
                        </TopBar>
                    }
                    {
                        topBarButtons &&
                        <TopBar>
                            {
                                setSearchValue &&
                                <Grid container item xs={9} lg={4}>
                                    <SearchTextField onChange={updateSearchValue} />
                                </Grid>
                            }
                            <TopBarButtonLayout>
                                {topBarButtons}
                            </TopBarButtonLayout>
                        </TopBar>
                    }
                    {children}
                </Grid>
            }
        </Grid>
    )
}
