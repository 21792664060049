import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent
} from '@mui/material';

interface Props {
    open: boolean,
    handleClose: Function,
    text: string,
    deleteAction: Function
}

/**
 * Dialog that confirms whether you want to delete something or not.
 * @param open The state that shows whether the dialog should be open or not.
 * @param handleClose The function that closes the dialog.
 * @param text The text that should be displayed in the dialog.
 * @param deleteAction The action that deletes the element.
 * @constructor
 */
export default function DeleteDialog({ open, handleClose, text, deleteAction }: Props) {
    return (
        <Dialog data-testid={"deleteDialog"} open={open}>
            <DialogContent>
                <p>{text}</p>
            </DialogContent>
            <DialogActions>
                <Button fullWidth={false} variant={"outlined"} onClick={() => handleClose()}>
                    Nee
                </Button>
                <Button fullWidth={false} onClick={() => deleteAction()} color={"error"}>
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    );
}
