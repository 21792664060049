import React, { ChangeEventHandler } from 'react';
import { InputAdornment, TextField, colors, styled } from '@mui/material';
import { Search } from '@mui/icons-material';

const SearchTextField = styled(TextField)(({ theme }) => ({
    "input.MuiInputBase-input": {
        paddingLeft: "0 !important",
    },
}));

const SearchIcon = styled(Search)(({ theme }) => ({
    color: colors.common.black,
    fontSize: "20px",
}));

interface Props {
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export default function SearchField({ onChange }: Props) {
    return (
        <SearchTextField
            type={"search"}
            placeholder={"Zoeken"}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                )
            }}
            onChange={onChange}
        />
    );
}