import React, {ReactNode} from 'react';
import jwtDecode from "jwt-decode";
import {useDispatch} from "react-redux";
import {Navigate} from "react-router-dom";
import {logout} from "@store/slices/auth";
import {useAppSelector} from "@store/hooks/hooks";
import {JwtPayload} from "@app-types/JwtPayload";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import userHasAccess from "@utils/helpers/security.helpers";

interface Props {
    role?: string,
    children: ReactNode
}

/**
 * Component that adds role based security to a page and logs out the user when they don't have permission.
 * @param role The role the user should have when navigating to this page.
 * @param children The page that should be secure.
 * @constructor
 */
export default function SecureElement({role, children}: Props): ReactJSXElement {
    const token = useAppSelector((state) => state.auth.token);
    const dispatch = useDispatch();

    let redirect = true;

    if(token !== null) {
        const user = jwtDecode<JwtPayload>(token);

        if (user.exp && Date.now() < user.exp * 1000) redirect = false;

        if(role) redirect = !userHasAccess(user.roles, role);
    }

    if (redirect) dispatch(logout());
    return redirect ? <Navigate to={"/login"} replace={true}/> : children as JSX.Element;
}
