export enum ProjectStatus {
    open = "open",
    onHold = "on hold",
    closed = "gesloten"
}

/**
 * Gets the tag type of a project status.
 * @param status The status whose tag type should be returned.
 */
export function getProjectStatusTagType(status: ProjectStatus) {
    switch (status) {
        case ProjectStatus.open:
            return "success";
        case ProjectStatus.onHold:
            return "warning";
        case ProjectStatus.closed:
            return "error";
        default:
            return "success";
    }
}
