import {logout} from "@store/slices/auth";
import {getProjectTypeErrorMessage} from "@utils/helpers/errorMessage.helpers";
import {useFormik} from "formik";
import {AnyObjectSchema} from "yup";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Autocomplete, Button, Chip, Grid, Skeleton, TextField} from "@mui/material";
import {FormSnackbar} from "@components/snackbars";
import {ProjectTypeRequestBody} from "@app-types/request-bodies/ProjectTypeRequestBody";
import {AutocompleteOption} from "@app-types/form-value-types/AutocompleteOption";
import {useGetAllChecklistsQuery} from "@store/api/checklists";
import {logoutIfInvalidToken} from "@utils/helpers/security.helpers";
import {useAppSelector} from "@store/hooks/hooks";

/**
 * @param closeSelf The function that closes the dialog.
 * @param projectName Default value for the name input field. (optional)
 * @param action The mutation action that should be executed on submit.
 * @param validationSchema The yup form validation schema that the form should use.
 * @param buttonText The text that the submit button should have.
 * @param projectTypeId The id of the project type that needs to be updated (optional)
 * @param showConfirmation Function that shows the confirmation snackbar with the correct message.
 */
interface Props {
    closeSelf: Function,
    action: Function,
    validationSchema: AnyObjectSchema,
    buttonText: string,
    projectTypeId?: any,
    projectType?: ProjectTypeRequestBody,
    showConfirmation: Function,
    setOpenConfirmationSnackbar: Function
}

/**
 * Project type form component that is used for adding and updating users.
 * @constructor
 */
export default function ProjectTypeForm(props: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {data, isSuccess} = useGetAllChecklistsQuery({});
    const [checklists, setChecklists] = useState<AutocompleteOption[]>([]);
    const token = useAppSelector((state) => state.auth.token);

    useEffect(() => {
        logoutIfInvalidToken(token, dispatch, navigate);
    });

    useEffect(() => {
        if (isSuccess) {
            let checklists: AutocompleteOption[] = [];
            data["hydra:member"].forEach((checklist: any) => {
                checklists.push({
                    label: checklist.name,
                    id: checklist.id
                })
            })
            setChecklists(checklists);

            if (props.projectType?.checklists) {
                const defaultChecklists: AutocompleteOption[] = [];

                props.projectType.checklists.forEach((checklist: any) => {
                    const splitChecklistUri = checklist.split("/");
                    const checklistId = +splitChecklistUri[splitChecklistUri.length-1];
                    const defaultChecklist = checklists.find((checklist) => checklist.id === checklistId);
                    if (defaultChecklist) {
                        defaultChecklists.push(defaultChecklist);
                    }
                });

                if (defaultChecklists?.length > 0) {
                    formik.setFieldValue("checklists", defaultChecklists);
                }
            }
        }
    }, [isSuccess, data, props.projectType]);

    /**
     * Initialization of the formik form.
     */
    const formik = useFormik({
        initialValues: {
            name: props.projectType?.name ?? "",
            checklists: []
        },
        validationSchema: props.validationSchema,
        onSubmit: async (values: ProjectTypeRequestBody) => {
            try {
                props.setOpenConfirmationSnackbar(false);

                let body = {...values};

                if(props.projectTypeId !== undefined) {
                    await props.action({id: props.projectTypeId, body: body}).unwrap();
                } else {
                    await props.action(body).unwrap();
                }

                props.showConfirmation();
                props.closeSelf();
            } catch (error: any) {
                if (error.data.message === "Expired JWT Token") {
                    dispatch(logout());
                    navigate("/login", {replace: true});
                }

                setErrorMessage(getProjectTypeErrorMessage(error));
                setOpenSnackbar(true);
            }
        }
    });

    return (
        <>
            {
                isSuccess ?
                <form data-testid={"form"} onSubmit={formik.handleSubmit} noValidate>
                    <TextField
                        id={"projectTypeName"}
                        name={"name"}
                        label={"Naam"}
                        type={"text"}
                        margin={"dense"}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        inputProps={{
                            "data-testid": "projectTypeName"
                        }}
                    />
                    <Autocomplete
                        multiple
                        disablePortal
                        autoComplete
                        filterSelectedOptions
                        options={checklists}
                        value={formik.values.checklists}
                        onChange={(e, value) => formik.setFieldValue("checklists", value)}
                        onBlur={formik.handleBlur}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                focused
                                className={"always-focused"}
                                id={"checklists"}
                                name={"checklists"}
                                label={"Templates (optioneel)"}
                                placeholder={"Selecteer templates"}
                                margin={"dense"}
                                error={formik.touched.checklists && Boolean(formik.errors.checklists)}
                                helperText={formik.touched.checklists && Boolean(formik.errors.checklists)}
                            />}
                        renderTags={(value: AutocompleteOption[], getTagProps) =>
                            value.map((option: any, index: number) => (
                                <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
                            ))
                        }
                        limitTags={2}
                    />
                    <Button data-testid={"submit"} type={"submit"}>{props.buttonText}</Button>
                    <FormSnackbar
                        open={openSnackbar}
                        setOpen={setOpenSnackbar}
                        message={errorMessage}
                        severity={"error"}
                    />
                </form> :
                <Grid container spacing={1}>
                    <Grid container item xs={12}>
                        <Skeleton variant={"rectangular"} height={53} width={355}/>
                    </Grid>
                    <Grid container item xs={12}>
                        <Skeleton variant={"rectangular"} height={53} width={355}/>
                    </Grid>
                    <Grid container item xs={12}>
                        <Skeleton variant={"rectangular"} height={53} width={355}/>
                    </Grid>
                </Grid>
            }
        </>
    );
}
