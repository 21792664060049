import {configureStore} from "@reduxjs/toolkit";
import {api} from "@store/api/api";
import {auth} from '@store/slices/auth'
import {persistReducer, persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage'

const authPersistConfig = {
    key: 'auth',
    storage,
}

const persistedAuthReducer = persistReducer(authPersistConfig, auth.reducer);

/**
 * Redux store which also implements persisted reducers with redux-persist.
 */
export const index = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: persistedAuthReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }).concat(api.middleware),
    devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(index);

export type AppRootState = ReturnType<typeof index.getState>;
export type RootState = AppRootState & {
    auth: ReturnType<typeof persistedAuthReducer>;
};
