import {api} from "@store/api/api";

export const checklistCategories = api.injectEndpoints({
    endpoints: (build) => ({
        /**
         * Get all items of a category.
         */
        getChecklistCategoryItems: build.query({
            query: (id) => ({
                url: `/api/checklist_categories/${id}/items`,
                method: "GET"
            }),
            providesTags: (result, error, id) => [
                result ? result["hydra:member"].map((item: any) => ({ type: "Item", id: item.id })) : "Item",
                { type: "ChecklistCategory", id: id }
            ]
        }),

        /**
         * Get a checklist category by its uri.
         */
        getChecklistCategory: build.query({
            query: (uri) => ({
                url: uri,
                method: "GET"
            }),
            providesTags: (result, error, uri) => [
                { type: "ChecklistCategory", id: uri.split("/").pop() }
            ]
        }),

        /**
         * Add checklist category endpoint.
         */
        addChecklistCategory: build.mutation({
            query: (body) => ({
                url: "/api/checklist_categories",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["ChecklistCategory"]
        }),

        /**
         * Delete checklist category endpoint.
         */
        deleteChecklistCategory: build.mutation({
            query: (id) => ({
                url: `/api/checklist_categories/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: "ChecklistCategory", id: "LIST" }]
        }),

        /**
         * Update checklist category endpoint.
         */
        updateChecklistCategory: build.mutation({
            query: ({id, body}) => ({
                url: `/api/checklist_categories/${id}`,
                method: "PUT",
                body: body
            }),
            invalidatesTags: ["ChecklistCategory"]
        }),

        /**
         * Add item to category endpoint.
         */
        addItemToCategory: build.mutation({
            query: (body) => ({
                url: "/api/checklist_category_items",
                method: "POST",
                body: body
            }),
            invalidatesTags: (result, error, body) => [
                { type: "ChecklistCategory", id: body.categoryId },
                { type: "Item", id: body.itemId },
                "Checklist"
            ]
        }),

        /**
         * Delete item from category endpoint.
         */
        deleteItemFromCategory: build.mutation({
            query: (id) => ({
                url: `/api/checklist_category_items/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["ChecklistCategory", "Checklist"]
        }),

        /**
         * Get a checklist category item by its uri.
         */
        getCategoryItem: build.query({
            query: (uri) => ({
                url: uri,
                method: "GET"
            }),
            providesTags: ["ChecklistCategory"]
        }),
    })
});

export const {
    useGetChecklistCategoryQuery,
    useGetChecklistCategoryItemsQuery,
    useAddChecklistCategoryMutation,
    useDeleteChecklistCategoryMutation,
    useUpdateChecklistCategoryMutation,
    useAddItemToCategoryMutation,
    useDeleteItemFromCategoryMutation,
    useGetCategoryItemQuery
} = checklistCategories;
