import { Grid, styled } from '@mui/material';

const PageHeadingGridItem = styled(Grid)({
    paddingTop: "0 !important",
    width: "100%",
    marginBottom: "16px !important",
});

PageHeadingGridItem.defaultProps = {
    item: true,
}

export default PageHeadingGridItem;