import {api} from "@store/api/api";
import {PaginationOptions} from "@app-types/PaginationOptions";

export const checklists = api.injectEndpoints({
    endpoints: (build) => ({
        /**
         * Add checklist endpoint.
         */
        addChecklist: build.mutation({
            query: (body) => ({
                url: "/api/checklists",
                method: "POST",
                body: body
            }),
            invalidatesTags: ["Checklist"]
        }),

        /**
         * Get checklist collection endpoint.
         */
        getAllChecklists: build.query<any, PaginationOptions>({
            query: (paginationOptions) => ({
                url: "/api/checklists",
                method: "GET",
                params: {
                    itemsPerPage: paginationOptions.itemsPerPage,
                    page: paginationOptions.page,
                    ...(paginationOptions.search && { search: paginationOptions.search })
                },
                refetchOnMountOrArgChange: true
            }),
            providesTags: ["Checklist"]
        }),

        /**
         * Get all categories of a checklist.
         */
        getChecklistCategories: build.query({
            query: (id) => ({
                url: `/api/checklists/${id}/categories`,
                method: "GET"
            }),
            providesTags: [{ type: "ChecklistCategory", id: "LIST" }]
        }),

        /**
         * Get a checklist by its uri.
         */
        getChecklist: build.query({
            query: (id) => ({
                url: `/api/checklists/${id}`,
                method: "GET"
            }),
            providesTags: ["Checklist"]
        }),

        /**
         * Delete checklist endpoint.
         */
        deleteChecklist: build.mutation({
            query: (id) => ({
                url: `/api/checklists/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Checklist"]
        }),

        /**
         * Update checklist endpoint.
         */
        updateChecklist: build.mutation({
            query: ({id, body}) => ({
                url: `/api/checklists/${id}`,
                method: "PUT",
                body: body
            }),
            invalidatesTags: ["Checklist"]
        }),
    })
});

export const {
    useAddChecklistMutation,
    useGetAllChecklistsQuery,
    useGetChecklistQuery,
    useDeleteChecklistMutation,
    useUpdateChecklistMutation,
    useGetChecklistCategoriesQuery
} = checklists;
