import React, {useEffect, useState} from "react";
import {Autocomplete, Button, Grid, Skeleton, TextField} from "@mui/material";
import {useFormik} from "formik";
import {useGetAllProjectsQuery} from "@store/api/projects";
import {AutocompleteOption} from "@app-types/form-value-types/AutocompleteOption";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetProjectTypeChecklistsQuery} from "@store/api/projectTypes";
import {useGetChecklistCategoriesQuery} from "@store/api/checklists";
import {ChecklistCategoryItemsForm} from "@components/forms/index";
import {useAddCheckMutation} from "@store/api/checks";
import {CheckRequestBody} from "@app-types/request-bodies/CheckRequestBody";
import {CheckFormValue} from "@app-types/form-value-types/CheckFormValue";
import {useNavigate} from "react-router-dom";
import {logout} from "@store/slices/auth";
import {useDispatch} from "react-redux";

export default function CheckForm() {
    const [selectedProjectType, setSelectedProjectType] = useState<string|null>(null);
    const [selectedChecklist, setSelectedChecklist] = useState<string|null>(null);
    const {data: projectsData, isSuccess: projectsIsSuccess} = useGetAllProjectsQuery({});
    const {data: checklistsData, isSuccess: checklistsIsSuccess} = useGetProjectTypeChecklistsQuery(selectedProjectType ?? skipToken);
    const {data: categoriesData, isSuccess: categoriesIsSuccess} = useGetChecklistCategoriesQuery(selectedChecklist ?? skipToken);
    const [addCheck] = useAddCheckMutation();
    const [projects, setProjects] = useState<AutocompleteOption[]>([]);
    const [checklists, setChecklists] = useState<AutocompleteOption[]>([]);
    const [showItems, setShowItems] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (categoriesIsSuccess && selectedChecklist) {
            setShowItems(true);
        } else {
            setShowItems(false);
        }
    }, [categoriesIsSuccess, selectedChecklist]);

    useEffect(() => {
        if (projectsIsSuccess) {
            let projectNames: AutocompleteOption[] = [];
            projectsData["hydra:member"].forEach((project: any) => {
                projectNames.push({
                    label: project.name,
                    id: project.id
                })
            })
            setProjects(projectNames);
        }
    }, [projectsIsSuccess, projectsData]);

    useEffect(() => {
        if (checklistsIsSuccess) {
            let checklistNames: AutocompleteOption[] = [];
            checklistsData["hydra:member"].forEach((checklist: any) => {
                checklistNames.push({
                    label: checklist.name,
                    id: checklist.id
                })
            })
            setChecklists(checklistNames);
        }
    }, [checklistsIsSuccess, checklistsData]);

    const formik = useFormik({
        initialValues: {
            project: null,
            checklist: null,
            results: []
        },
        onSubmit: async (values: CheckFormValue) => {
            try {
                let body: CheckRequestBody = {
                    projectId: values.project?.id,
                    checklistId: values.checklist?.id,
                    results: values.results
                };

                await addCheck(body).unwrap();
                navigate("/checks", {state: {openWithConfirmationMessage: "De check is succesvol opgeslagen."}});
            } catch (error: any) {
                if (error.data.message === "Expired JWT Token") {
                    dispatch(logout());
                    navigate("/login", {replace: true});
                }
            }
        }
    });

    function clearChecklist() {
        formik.setFieldValue("checklist", null);
        setChecklists([]);
        setSelectedChecklist(null);
        formik.values.results.length = 0;
    }

    return (
        <Grid container item pt={"0 !important"} className={"content"} xs>
            <form data-testid={"form"} onSubmit={formik.handleSubmit} noValidate>
                {
                    projectsIsSuccess ?
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            multiple={false}
                            fullWidth
                            disablePortal
                            autoComplete
                            filterSelectedOptions
                            value={formik.values.project}
                            onChange={(e, value) => {
                                clearChecklist();
                                value ?
                                    setSelectedProjectType(projectsData["hydra:member"].find((project: any) => project.id === value.id).type) :
                                    setSelectedProjectType(null);
                                formik.setFieldValue("project", value);
                            }}
                            onBlur={formik.handleBlur}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    id={"project"}
                                    name={"project"}
                                    label="Project"
                                    margin={"normal"}
                                    error={formik.touched.project && Boolean(formik.errors.project)}
                                    helperText={formik.touched.project && formik.errors.project}
                                />}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.id}>
                                    <span>{option.label}</span>
                                </li>
                            )}
                            options={projects}
                        />
                        <Autocomplete
                            multiple={false}
                            fullWidth
                            disablePortal
                            autoComplete
                            filterSelectedOptions
                            value={formik.values.checklist}
                            onChange={(e, value) => {
                                formik.values.results.length = 0;
                                value ?
                                    setSelectedChecklist(checklistsData["hydra:member"].find((checklist: any) => checklist.id === value.id).id) :
                                    setSelectedChecklist(null);
                                formik.setFieldValue("checklist", value);
                            }}
                            onBlur={formik.handleBlur}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    id={"checklist"}
                                    name={"checklist"}
                                    label="Template"
                                    margin={"normal"}
                                    error={formik.touched.checklist && Boolean(formik.errors.checklist)}
                                    helperText={formik.touched.checklist && formik.errors.checklist}
                                />}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.id}>
                                    <span>{option.label}</span>
                                </li>
                            )}
                            options={checklists}
                        />
                    </Grid> :
                    <Grid item xs={12} md={4}>
                        <Grid item mt={"18px"}>
                            <Skeleton variant={"rectangular"} height={"30px"}/>
                        </Grid>
                        <Grid item mt={"24px"}>
                            <Skeleton variant={"rectangular"} height={"30px"}/>
                        </Grid>
                    </Grid>

                }
                {
                    showItems && categoriesIsSuccess &&
                    <Grid container item pt={"15px"} xs={12}>
                        {
                            categoriesData["hydra:member"].map((category: any) => (
                                category.categoryItems.length > 0 &&
                                <ChecklistCategoryItemsForm
                                    key={category.id}
                                    category={category}
                                    formik={formik}
                                />
                            ))
                        }
                        <Grid container item justifyContent={"end"} xs={12}>
                            <Button
                                fullWidth={false}
                                sx={{
                                    minWidth: "150px",
                                    fontSize: "13px",
                                    justifySelf: "end",
                                    marginTop: "20px !important"
                                }}
                                type={"submit"}
                            >
                                Opslaan
                            </Button>
                        </Grid>
                    </Grid>
                }
            </form>
        </Grid>
    );
}
